/*--
    11. Widget Css
---------------------*/
.widget-title {
    font-weight: 600;
    position: relative;
    padding-left: 10px;
    padding-bottom: 6px;
    margin-bottom: 30px;
    display: inline-block;
    margin-top: -5px;
    &::after {
        position: absolute;
        content: '';
        background: $theme-color;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
    }
    &::before {
        position: absolute;
        content: '';
        background: $theme-color;
        width: 1px;
        height: 25px;
        left: 0;
        bottom: 0;
    }
}
.left-sidebar {
    padding-right: 20px;
    @media #{$large-mobile,$tablet-device}{
        padding-right: 0px;
    }
}
.right-sidebar {
    padding-left: 20px;
    @media #{$large-mobile,$tablet-device}{
        padding-left: 0px;
    }
}
.left-sidebar {
    padding-right: 20px;
}
.single-widget {
    margin-top: 60px;
    &:first-child {
        margin-top: 30px;
    }
    @media #{$large-mobile,$tablet-device}{
        margin-top: 35px;
    }
}
.widget-search-form {
    & input {
        width: 100%;
        padding: 10px;
        border-radius: 25px;
        border: 1px solid $theme-color;
    }
}
.widget-categories {
    & ul {
        & li {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            & a {
                display: block;
                & span {
                    float: right;
                }
            }
        }
    }
}

.widgets  {
    @media #{$tablet-device,$large-mobile}{
        padding-top: 30px;
    }
}
.single-related {
    position: relative;
    padding-left: 130px;
    min-height: 80px;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    @media #{$small-mobile}{
        padding-left: 0px;
    }
}
.single-related-image {
    position: absolute;
    left: 0;
    top: 5px;
    overflow: hidden;
    width: 120px;
    @media #{$small-mobile}{
        position: inherit;
        width: 100%;
        margin-bottom: 20px;
    }
}
.single-related-contents {
    & h4 {
        font-weight: 600;
        margin-bottom: 2px;
    }
    .post_meta {
        margin-bottom: 5px;
    }
    
}
.tweet-cont-wrap {
    & ul {
        & li {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            & > a {
                color: $theme-color;
                font-weight: 600;
            }
            & span {
                color: $theme-color;
                font-weight: normal;
                margin-top: 8px;
            }
        }
    }
}
.tag {
    margin-bottom: -8px;
    margin-right: -5px;
    margin-left: -5px;
    margin-top: -5px;
    & a {
        color: $theme-color;
        border: 1px solid $theme-color;
        padding: 6px 12px;
        border-radius: 25px;
        margin: 4px;
        margin-bottom: 10px;
        &:hover {
            color: $white;
            background: $theme-color;
        }
    }
}
.our-widget-agent {
    margin-left: -10px;
    margin-right: -10px;
    & .widget-agent {
        width: 43%;
        float: left;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        text-align: center;
        margin: 0px 10px 20px 10px;
        border-radius: 10px;
        & .name {
            background: #f1f1ff;
            padding: 10px;
            & h5 {
                margin-bottom: 0;
                
            }
        }
        @media #{$desktop-device}{
            width: 41%;
        }
    }
}



/*-- 
   shop sidebar Css
------------------------------*/

.shop-sidebar {
    & h4 {
        font-size: 18px;
        font-weight: 600;
        color: $black;
        margin-bottom: 25px;
        padding-bottom: 15px;
        position: relative;
        text-transform: uppercase;
        &::before {
            position: absolute;
            content: "";
            background: $theme-color;
            width: 50px;
            height: 2px;
            left: 0;
            bottom: -1px;
        }
    }
   & ul {
        & > li {
            display: block;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            & > a {
                font-size: 15px;
                display: block;
                font-weight: 500;
                & span {
                    float: right;
                }
            }
        }
    }
}
.sidbar-product {
    & .sidbar-product-inner {
        overflow: hidden;
        display: flex;
        margin-top: 20px;
        & .product-image {
            padding-right: 10px;
            width: 100px;
        }
        & .product-content {
            margin-top: 0;
            & h3 {
                font-size: 15px;
            }
            & .price-box {
                font-size: 16px;
            }
        }
    }
}
.ui-widget-content {
    background: #777 none repeat scroll 0 0;
    border: medium none;
    border-radius: 0px;
    height: 4px;
    margin: 35px 0 25px;
    & .ui-state-default {
        background: $white;
        border: 3px solid $theme-color;
        border-radius: 25px;
        box-shadow: none;
        color: #ffffff;
        cursor: pointer;
        font-weight: normal;
        height: 15px;
        width: 15px;
        &:hover {
            background: $theme-color;
        }
    }
    & .ui-slider-range {
        background: $theme-color;
    }
}
.filter-price-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}
.filter-price-cont { 
    font-size: 14px;
    & .input-type {
        & input {
            border: medium none;
            padding: 0 8px 0 0;
            text-align: center;
            width: 40px; 
        }
        &:last-child {
            padding-left: 8px;
        }
    } 
    & .add-to-cart-button {
        background: $theme-color;
        display: inline-block;
        font-weight: 500;
        padding: 1px 13px;
        right: 0;
        color: $white;
        &:hover {
          background: $black;  
        }
    }
}
.sidebar-tag {
    & > a {
        border: 1px solid $black;
        color: $black;
        font-size: 14px;
        font-weight: 500;
        margin: 10px 10px 0  0;
        padding: 4px 15px;
        &:hover {
            background: $theme-color;
            color: $white;
            border: 1px solid $theme-color;
        }
    }
}
.shop-top-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    align-items: center;
}
.shop-item-filter-list {
    & li {
        & a {
            font-size: 26px;
            font-weight: 500;
            margin-right: 20px;
            & .active {
                color: $theme-color;
            }
        }
        & .active {
            color: $theme-color;
        }
    }
}
.product-short {
    display: flex;
    font-size: 14px;
    & p {
        margin: 0 15px 0 0;
    }
    & .nice-select {
        border: 1px solid #ddd;
        padding: 0 8px;
        height: 30px;
    }
}
.shop-sidebar-wrap {
    @media #{$tablet-device}{
        margin-top: 80px;
    }
    @media #{$large-mobile}{
        margin-top: 70px;
    }
    @media #{$small-mobile}{
        margin-top: 60px;
    }
}
.product-layout-list {
    & p {
        margin-top: 20px;
    }
    &:hover .product-action {
        opacity: 1;
        visibility: visible;
        transform: translateY(-50%);
    }
    &:hover .product-image a::before {
        opacity: 1;
    }
}


