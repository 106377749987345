/*--
    09. Bloag Css
-----------------------------*/
.single-blog {
    & .blog-image {
        position: relative;
    }
    & .meta-tag {
        width: 80px;
        background: $theme-color;
        height: 60px;
        line-height: 60px;
        text-align: center;
        position: absolute;
        color: #fff;
        top: 0;
        & span {
            font-size: 20px;
            font-weight: 600;
        }
    }
    & .blog-content {
        margin-top: 25px;
        max-width: 480px;
        & .read-more {
            margin-top: 15px;
            & a {
                font-weight: 600;
            }
        }
    }
}

.our-blog-contnet {
    padding: 30px;
    border-bottom: 1px solid #ececec;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
    & h5 {
        font-weight: 600;
        margin-bottom: 5px;
    }
    & .post_meta {
        margin-bottom: 10px;
        font-size: 12px;
        & ul {
            & li {
                display: inline-block;
                position: relative;
                padding: 0 10px 0 0;
                margin: 0 10px 0 0;
                position: relative;
                &::before {
                    content: "";
                    width: 1px;
                    height: 10px;
                    background: #7c7c7c;
                    top: 50%;
                    right: 0;
                    position: absolute;
                    transform: translateY(-50%);
                    
                }
                &:last-child {
                    &::before {
                        display: none;
                        
                    }
                }
                & a {
                    color: $theme-color;
                }
            }
        }
    }
    & > p {
        font-size: 14px;
    }
    & .button-block {
        margin-top: 20px;
        & .botton-border {
            padding: 5px 30px;
            border: 2px solid $theme-color;
            color: $theme-color;
            border-radius: 5px;
            font-size: 12px;
            text-transform: uppercase;
            &:hover {
                background: $theme-color;
                color: $white;
            }
        }
    }
}
.single-our-blog {
    &:hover {
        .our-blog-image {
            & a {
                position:relative;
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }
            & .in-our-blog-icon {
                opacity: 1;
                visibility: visible;
                margin-top: 0px;
            }
        }
    }
}
.our-blog-image {
    position:relative;
    & a {
        position:relative;
        display: block;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            background: rgba(0, 0, 0, 0.58);
            @include transition(0.3s);
        }
        & img {
            width: 100%;
        }
    }
    & .in-our-blog-icon {
        position: absolute;
        width: 50px;
        height: 50px;
        line-height: 45px;
        text-align: center;
        background: #fff;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-size: 50px;
        margin-top: 30px;
        @include transition(0.3s);
    }
}

.blog-top-bar {
    display: flex;
    justify-content: space-between;
    background: #f6f6f6;
    padding: 10px 20px;
    color: $theme-color;
    margin-top: 30px;
    @media #{$large-mobile}{
        padding: 6px;
    }
    
    & .blog-item-filter-list {
        & li {
            & a {
                font-size: 20px;
                margin-left: 15px;
                padding-left: 15px;
                position: relative;
                &::before {
                    background: $theme-color;
                    content: "";
                    position: absolute;
                    height: 15px;
                    width: 1px;
                    left: 0;
                    top: 6px;
                    
                }
                
            }
            &:first-child {
                & a {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.single-blog-list-view {
    & .our-blog-contnet {
        padding: 0;
        border: none;
        @media #{$large-mobile}{
            margin-top: 30px;
        }
    }
}

.blog-details-area {
    & .our-blog-contnet {
        padding: 30px 0;
        border: none;
        border-bottom: 1px solid #ddd;
    }
}
.blockquote-blog {
    background: $theme-color;
    padding: 20px 70px;
    color: $white;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    
    
    &::after {
        position: absolute;
        width: 40px;
        height: 40px;
        background: url(../images/icon/small-quote-icon.png) no-repeat;
        content: "";
        left: 35px;
        top: 25px;
        z-index: 1;
        right: auto;
    }
    &::before {
        position: absolute;
        width: 80px;
        height: 80px;
        background: url(../images/icon/quote-icon01.png) no-repeat;
        content: "";
        right: 0;
        bottom: 10px;
        z-index: 1;
        left: auto;
    }
    @media #{$large-mobile}{
        padding: 30px 30px;
        &::after {
            left: 15px;
            top: 15px;
        }
    }
    & p {
        margin: 0;
    }
    & h5 {
        margin: 0;
        float: right;
        font-weight: 500;
        color: $white;
        position: relative;
        padding-left: 45px;
        
        &::after {
            position: absolute;
            width: 35px;
            height: 1px;
            background: #ddd;
            content: "";
            left: 0;
            top: 50%;
        }
    }
}

.admin-author-details {
    margin-top: 60px;
    border-top: 1px solid #ddd;
    padding-top: 60px;
}
.admin-aouthor {
    display: flex;
    align-items: center;
    background: #f9f8f8;
    padding: 30px;
    & .admin-image {
        width: 40%;
    } 
}
.admin-info {
    & .name {
        & h5 {
            font-weight: 700;
        }
        & p {
            color: $theme-color;
            margin-bottom: 10px;
        }
    }
    & .author-socialicons {
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 20px;
        & li {
            list-style: none;
            display: inline-block;
            & a {
                display: block;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: $theme-color;
                color: $white;
                text-align: center;
                border-radius: 100%;
                margin: 2.5px;
                font-size: 16px;
                &:hover{
                    background: #333;
                }
            }
        }
    }
}
.review_address_inner {
    position: relative;
    & > h5 {
        font-weight: 600;
        margin-bottom: 30px;
    }
}
.pro_review {
    display: flex;
    margin-bottom: 50px;
    position: relative;
    &:nth-child(3n) {
        padding-left: 120px;
        @media #{$large-mobile}{
          padding-left: 20px;
        }
    }
    @media #{$small-mobile}{
      margin-bottom: 20px;
    }
}
.review_thumb {
    margin-right: 20px;
    min-width: 20%;
    @media #{$large-mobile}{
        min-width:25%;
    }
}
.review_info {
    & h5 {
        font-weight: 600;
    }
}
.rating_send {
    position: absolute;
    top: 0;
    right: 0;
    & a {
        display: block;
        float: right;
        font-size: 16px;
        margin-top: 0px;
    }
}
.comments-reply-area {
    & .comment-reply-title {
        font-weight: 600;
    }
    & .comment-input {
        & input {
            width: 100%;
            margin-bottom: 30px;
            border: 1px solid #ddd;
            padding: 6px 20px;
        }
        & textarea {
            width: 100%;
            height: 200px;
            border: 1px solid #ddd;
            padding: 20px;
        }
    }
    & .comment-form-submit {
        margin-top: 30px;
        & .comment-submit {
            background: $theme-color;
            color: $white;
            font-weight: 600;
            border: none;
            text-transform: uppercase;
            padding: 8px 30px;
            font-size: 16px;
            letter-spacing: 1px;
            border-radius: 5px;
            &:hover {
                background: #333;
            }
        }
    }
}
.comments-reply-area {
    margin-top: 30px;
    border-top: 1px solid #ddd;
    padding-top: 60px;
}