/*--
    10. Footer Css
-------------------------*/
.footer-top{
    background: #f6f6f6;
}
.widget-footer {
    & .footer-logo {
        margin-bottom: 10px;
        margin-top: -5px;
    }
    & .title-widget {
        margin-bottom: 30px;
    }
}
.newsletter-footer {
    margin-top: 18px;
    & input {
        width: 100%;
        max-width: 300px;
        border: none;
        border-bottom: 1px solid #ddd;
        padding: 10px 0;
        background-color: transparent;
    }
    & .subscribe-button {
        width: 100%;
    }
    & .subscribe-btn {
        padding: 6px 20px;
        border: none;
        background: $theme-color;
        color: $white;
        margin-top: 12px;
    }
}

.footer-list {
    & li {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.footer-contact {
    & li {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    & label {
        display: block;
        font-weight: 500;
        margin-bottom: 2px;
    }
}
.footer-blog {
    & li {
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.widget-blog-wrap {
    position: relative;
}
.widget-blog-image {
    max-width: 76px;
    position: absolute;
    min-height: 70px;
}
.widget-blog-content {
    padding-left: 80px;
    & h6 {
        font-weight: 400;
        font-size: 14px;
    }
    & span {
        font-size: 13px;
        margin-right: 8px;
    }
}
.footer-bottom {
    background: #f1f1f1;
    padding: 20px 0;
}
