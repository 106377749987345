
/*--
    03. Hero Css
--------------------------------*/
.hero-slider-one {
    & .single-slide {
        align-items: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @include flex(flex);
        height: 820px;
        position: relative;
        z-index: 1;
        &::before {
            background: rgba(0, 0, 0, 0.15);
            content: '';
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            position: absolute;
            z-index: -1;
        }
        @media #{$laptop-device}{
            height: 680px
        }
        @media #{$desktop-device}{
            height: 600px
        }
        @media #{$tablet-device}{
            height: 500px
        }
        @media #{$large-mobile}{
            height: 480px;
        }
        @media #{$small-mobile}{
            height: 420px;
        }
    }
    & .slick-arrow {
        background-color: rgba(0, 0, 0, 0.2);
        border: medium none;
        border-radius: 100px;
        color: #ffffff;
        height: 60px;
        position: absolute;
        top: 50%;
        width: 60px;
        z-index: 999;
        font-size: 20px;
        visibility: hidden;
        @include opacity(0);
        @include transition(0.4s);
        &:hover {
            background-color: $theme-color;
        }
        @media #{$small-mobile} , #{$large-mobile}{
            height: 40px;
            width: 40px;
        }
    }
    & .slick-arrow.slick-next {
      right: 15px;
    }
    & .slick-arrow.slick-prev {
      left: 15px;
    }
    &:hover .slick-arrow{
        visibility: visible;
        @include opacity(1);
        @include transform(translateY(-50%));
    }
}
.slick-current {
    /*-- Hero Content One --*/
    & .hero-content-one {
        & h2 {
            animation-name: fadeInBottom;
            animation-duration: 0.3s;
        }
        & h1 {
            animation-name: fadeInBottom;
            animation-duration: 0.5s;
        }
        & p {
            animation-name: fadeInBottom;
            animation-duration: 0.7s;
        }
        & .btn {
            animation-name: fadeInBottom;
            animation-duration: 0.8s;
        }
        & .slider-inner-image{
            @include opacity(1);
            animation-name: fadeInRight;
            animation-duration: 0.9s;
        }
    }
}
.hero-slider-two {
    & .single-slide-two {
        align-items: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @include flex(flex);
        height: 950px;
        position: relative;
        z-index: 1;
        @media #{$laptop-device}{
            height: 680px
        }
        @media #{$desktop-device}{
            height: 600px
        }
        @media #{$tablet-device}{
            height: 500px
        }
        @media #{$large-mobile}{
            height: 480px;
        }
        @media #{$small-mobile}{
            height: 450px;
        }
    }
    & .slick-arrow {
        background-color: rgba(0, 0, 0, 0.2);
        border: medium none;
        border-radius: 100px;
        color: #ffffff;
        height: 60px;
        position: absolute;
        top: 50%;
        width: 60px;
        z-index: 999;
        font-size: 20px;
        visibility: hidden;
        @include opacity(0);
        @include transition(0.4s);
        &:hover {
            background-color: $theme-color;
        }
        @media #{$small-mobile} , #{$large-mobile}{
            height: 40px;
            width: 40px;
        }
    }
    & .slick-arrow.slick-next {
      right: 15px;
    }
    & .slick-arrow.slick-prev {
      left: 15px;
    }
    &:hover .slick-arrow{
        visibility: visible;
        @include opacity(1);
        @include transform(translateY(-50%));
    }
    
}
.hero-slider-one, .hero-slider-two {
    & .slider-text-info {
        z-index: 1;
        & h2 {
            & span {
                color: $theme-color;
            }
            font-weight: 600;
            @media #{$desktop-device}{
                font-size: 30px;
            }
            @media #{$tablet-device}{
                font-size: 20px;
                line-height: 25px;
            }
            @media #{$large-mobile}{
                font-size: 20px;
                line-height: 25px;
            }
            @media #{$small-mobile}{
                font-size: 20px;
                margin-bottom: 0px;
                line-height: 30px;
            }
        }
        & h1 {
            text-transform: capitalize;
            margin-bottom: 20px;
            & span {
                color: $theme-color;
            }
            @media #{$desktop-device}{
                font-size: 44px;
            }
            @media #{$tablet-device}{
                font-size: 40px;
            }
            @media #{$large-mobile}{
                font-size: 30px;
            }
            @media #{$small-mobile}{
                font-size: 28px;
                margin-bottom: 10px;
            }
        }
        & p{
            line-height: 24px;
            margin: 0;
            max-width: 500px;
        }
        & .slider-btn {
            font-weight: 600;
            margin-top: 60px;
            @include transition(0.4s);
            position: relative;
            &::before {
                position: absolute;
                content: "";
                left: 0;
                top: -40px;
                height: 30px;
                width: 1px; 
                background: $theme-color;
            }
            @media #{$tablet-device}{
            }
            @media #{$large-mobile}{
            }
            @media #{$small-mobile}{
            }
        }
    }
    & .slider-inner-image {
        justify-content: center;
        @include flex(flex);
        @include opacity(0);
    }
   
    @media #{$tablet-device}{
        & .slider-inner-image{
            display: none;
        }
    }
     @media #{$large-mobile}{
        & .slider-inner-image{
            display: none;
        }
    }
    @media #{$small-mobile}{
        & .slider-inner-image{
            display: none;
        }
    }
}
.hero-slider-two {
    & .slider-text-info {
        margin-top: 80px;
    }
    & .slider-btn-two {
        font-weight: 600;
        margin-top: 20px;
        @include transition(0.4s);
        position: relative;
        &::before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -40px;
            height: 30px;
            width: 1px; 
            background: $theme-color;
        }
        @media #{$tablet-device}{
        }
        @media #{$large-mobile}{
        }
        @media #{$small-mobile}{
        }
    }
    .social-top {
        margin-top: 40px;
    }
}
.slick-current {
    /*-- Hero Content One --*/
    & .hero-content-one {
        & h3 {
            animation-name: fadeInBottom;
            animation-duration: 0.3s;
        }
        & h1 {
            animation-name: fadeInBottom;
            animation-duration: 0.5s;
        }
        & p {
            animation-name: fadeInBottom;
            animation-duration: 0.7s;
        }
        & .btn {
            animation-name: fadeInBottom;
            animation-duration: 0.8s;
        }
        & .slider-inner-image{
            @include opacity(1);
            animation-name: fadeInRight;
            animation-duration: 0.9s;
        }
    }
}
.hero-slider-one .slick-dots {
    text-align: center;
    margin-top: 20px;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
}

.hero-slider-one .slick-dots li {
    display: inline-block;
    margin: 0 4px;
}

.hero-slider-one .slick-dots li button {
    background-image: url(../images/icon/slider-btn-01.png);
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    border-radius: 50%;
}
.hero-slider-one .slick-dots li.slick-active button {
    background-image: url(../images/icon/slider-btn-active.png);
}
