/*--
    02. Header Css
-----------------------*/
.header-top-area {
    
}
.transparent-header {
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 111;
    margin: auto;
    width: 100%;
    border-bottom: 1px solid #ddd;
}
.header-top-inner {
    border-bottom: 1px solid #ddd;
}
.top-info {
    padding: 10px 0;
    & li {
        display: inline-block;
        margin-right: 15px;
        padding-right: 15px;
        position: relative;
        @media #{$tablet-device}{
            margin-right: 12px;
            padding-right: 12px;
        }
        &::after {
            height: 15px;
            width: 1px;
            content: '';
            position: absolute;
            background: $black;
            right: 0;
            top: 50%;
            transform: translate(0%, -50%) rotate(20deg);
        }
        &:last-child  {
            margin-right: 0px;
            padding-right: 0px;
            &::after{ 
                display: none;
            }
        }
        & a {
            
        }
    }
}

.social-top {
    padding: 10px 0;
    & ul {
        & li {
            display: inline-block;
            margin-right: 20px;
            & a {
                font-size: 16px;
            }
        }
    }
}

/*--
    - Header Bottom Css
-------------------------------*/
.logo {
    margin: 40px 0;
    @media #{$tablet-device }{
        margin: 26px 0;
    }
    @media #{$small-mobile}{
        width: 125px;
        margin: 25px 0;
    }
    @media #{$large-mobile}{
        margin: 25px 0;
    }
}
.main-menu-area {
    & ul {
        & > li {
            display: inline-block;
            position: relative;
            padding:0 20px;
            & > a {
                display: block;
                font-size: 14px;
                font-weight: 500;
                padding: 30px 0;
                text-transform: uppercase;
                position: relative;
                @include transition(.4s);
                &::before {
                    content: "";
                    background: $theme-color;
                    width: 0%;
                    height: 2px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    @include opacity(0);
                    @include transition(.4s);
                }
            }
            &:first-child {
                padding-left: 0;
            }
            &:hover{
                & > a {
                    &::before{
                       visibility: visible;
                       @include opacity(1);
                       width: 100%;
                    }
                }
            }
            
            &:hover {
                .sub-menu, .mega-menu {
                    visibility: visible;
                    @include opacity(1);
                    top: 100%;
                }
            }
            
        }
    }
}
/*--
    - Main Menu Css
---------------------*/
.main-menu-area{
   .sub-menu {
        background: $white;
        left: 0;
        padding: 15px;
        position: absolute;
        text-align: left;
        width: 180px;
        z-index: 99;
        top: 120%;
        visibility: hidden;    
        @include opacity(0);
        @include transition(.3s);

        & > li {
            padding: 0;
            display: block;
            &:first-child {
                margin-bottom: 0;
            }

            & > a {
                padding: 0;
                font-weight: 400;
                margin-bottom: 8px;
                text-transform: capitalize;
                &::before{
                   display: none;
                }
            }
        }
    } 
}
// mega-menu css
.main-menu-area {
    .mega-menu {
        background: $white;
        left: 0;
        padding: 30px 20px;
        position: absolute;
        text-align: left;
        width: 640px;
        z-index: 99;
        top: 120%;
        visibility: hidden;
        @include opacity(0);
        @include transition(.3s);
        box-shadow: 0, 0, 0, 0.1 rgba(0, 0, 0, 0.1);
        & >li {
            width: 33.333%;
            float: left;
            padding: 0;

            & > a {
                padding: 0;
                margin-bottom: 20px;
                padding-bottom: 5px;
                &::before {
                   display: none;
                }
                &::after {
                    content: "";
                    background: $theme-color;
                    width: 40px;
                    height: 2px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    @include transition(.4s);
                }
            }
            & ul {
                & li {
                    display: block;
                    padding: 0;
                    & a {
                        padding: 0;
                        text-transform: capitalize;
                        display: block;
                        font-weight: 400;
                        margin-top: 8px;
                        &::before{
                           display: none;
                        }
                    }
                }
            }
        }
    }
}
// sidebar-search
.trigger-search {
  display: inline-block;
}
.main-search-active {
	background: rgba(0, 0, 0, 0.92) none repeat scroll 0 0;
	width: 100%;
	min-height: 100vh;
	padding: 32px 46px 39px;
	position: fixed;
	top: 0;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    visibility: hidden;
    opacity: 0;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	z-index: 9999;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.sidebar-search-icon {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    border-radius: 100%;
    color: #ffffff;
    font-size: 30px;
    line-height: 1;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 20px;
}
.sidebar-search-icon {
    & button {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        color: #ffffff;
        cursor: pointer;
        font-size: 30px;
        line-height: 1;
        transition: all 0.3s ease 0s;
        &:hover {
          color: $theme-color;
        }
    }
}
.sidebar-search-input {
    -ms-flex-item-align: center;
	    align-self: center;
	display: -webkit-box;
	display: -ms-flexbox;
    @include flex (flex);
    & .form-search{
        position: relative;
        & input {
            background-color: rgba(0, 0, 0, 0);
            border-color: #dddddd;
            border-style: solid;
            border-width: 0 0 1px;
            color: #ffffff;
            display: block;
            font-size: 16px;
            height: 70px;
            line-height: 70px;
            padding: 15px 50px 15px 0;
            width: 800px;
            &::placeholder{
                color: #fff;
            }
            &::-webkit-input-placeholder {
                color: #fff;
            }
            &::-moz-placeholder {
                color: #fff;
            }
            &::-ms-input-placeholder {
                color: #fff;
            }
            &::placeholder{
                color: #fff;
            }
            @media #{$tablet-device}{
                width: 500px;
            } 
            @media #{$large-mobile}{
                width: 400px;
            } 
            @media #{$small-mobile}{
                width: 280px;
            } 
        }
        & button {
            background-color: rgba(0, 0, 0, 0);
            border: medium none;
            color: #ffffff;
            cursor: pointer;
            font-size: 20px;
            padding: 0;
            position: absolute;
            right: 0;
            top: 25px;
            transition: all 0.3s ease 0s;
            width: 40px;
            &:hover {
              color: $theme-color;
            }
        }
    }
}
.main-search-active.inside {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
}
/*--
    - Right Blok Box Css
---------------------------*/
.right-blok-box {
    justify-content: flex-end;
    margin: 0px 0;
    @media #{$tablet-device }{
        margin: 34px 50px 24px 0;
    }
    @media #{$large-mobile}{
        margin: 34px 0px 24px 0;
    }
    @media #{$small-mobile}{
        margin: 28px 4px 24px 0;
    }
    a {
        font-size: 24px;
        @media #{$small-mobile}{
            font-size: 20px;
        }
    }
}
.user-wrap {
    padding: 0 20px;
    @media #{$small-mobile}{
        padding: 0 15px;
    }
}
/*-- 
    - Shopping Cart Css
----------------------------*/
.shopping-cart-wrap {
    position: relative;
    & ul.mini-cart {
        position: absolute;
        width: 300px;
        background: #fff;
        box-shadow: 0 0 3px 2px #dddddd;
        right: 0;
        top: 130%;
        padding: 15px;
        z-index: 99;
        visibility: hidden;
        @include opacity(0);
        transition: all 0.3s ease-in-out;
        .cart-item {
            display: flex;
            padding-bottom: 15px;
            position: relative;
            & .cart-image {
                border: 1px solid #f1f1f1;
                display: block;
                width: 100px;
            }
            .cart-title{
               padding-left: 15px;
               width: 60%;
                & h4{
                    font-size: 15px;
                    font-weight: 500;
                }
                & .quantity {
                    display: block;
                }
                & .price-box{
                    color: $theme-color;
                    font-weight: 500;
                    .old-price{
                        color: #666;
                        text-decoration: line-through;
                    }
                }
                & .remove_from_cart{
                    margin-top: 10px;
                    position: absolute;
                    right: 0;
                    font-size: 14px;
                }
            }
        } 
        & .subtotal-titles {
            & .subtotal-titles{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-weight: 600;
                color: $black;
                h3{
                    font-size: 14px;
                    font-weight: 600;
                    color: $black; 
                    line-height: 20px;
                    margin: 0;
                }
            }
        }
        @media #{$large-mobile}{
            right: -30px;
            width: 280px;
        }
    }
    &:hover ul.mini-cart{
        visibility: visible;
        @include opacity(1);
        top: 100%;
    }
}
#cart-total { 
    background: $theme-color;
    border-radius: 100%;
    color: $white;
    float: right;
    font-size: 10px;
    font-weight: 500;
    height: 18px;
    line-height: 18px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    top: -2px;
    width: 18px;
    right: 8px;
}
.mini-cart-btns {
    & .cart-btns {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        width: 100%;
        & a {
            background: $theme-color;
            border: 2px solid $theme-color;
            border-radius: 50px;
            color: #ffffff;
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            height: 40px;
            line-height: 36px;
            padding: 0 25px;
            text-align: center;
            text-transform: uppercase;
            &:hover {
                background: $black;
                border: 2px solid $black;
            }
        }
    }
}


/*---------------------------
    - Mobile menu css
-----------------------------*/
.mobile-header .category-toggle {
    padding: 8px 15px;
}

.mobile-header-top {
    border-bottom: 1px solid #ebebeb;
}

.mobile-header-top .header-top-settings {
    float: none;
}

.mobile-logo {
    max-width: 140px;
    width: 100%;
}

.mobile-main-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .mobile-main-header {
        border-bottom: 1px solid #ebebeb;
    }
}

.mobile-main-header .mobile-menu-toggler {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    position: relative;
    margin-left: 30px;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a {
    color: #252525;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a:hover {
    color: $theme-color;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
    font-size: 13px;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    font-weight: 600;
    right: -8px;
    top: 0;
    position: absolute;
    background-color: $theme-color;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
    margin-left: 15px;
}
 .mobile-menu-btn .off-canvas-btn {
    font-size: 23px;
    cursor: pointer;
    padding: 0px 10px;
    line-height: 1;
    padding-right: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn .off-canvas-btn:hover {
    color: $theme-color;
}

.mobile-navigation {
    overflow: hidden;
    max-height: 250px;
}

.mobile-navigation nav {
    height: 100%;
}

.mobile-menu {
    margin-top: 30px;
    height: 100%;
    overflow: auto;
    padding-right: 30px;
    margin-right: -30px;
}

@media only screen and (max-width: 479.98px) {
    .mobile-menu {
        margin-top: 15px;
    }
}

.mobile-menu li>a {
    font-size: 15px;
    color: #252525;
    text-transform: capitalize;
    line-height: 18px;
    position: relative;
    display: inline-block;
    padding: 10px 0;
}

.mobile-menu li>a:hover {
    color: $theme-color;
}

.mobile-menu li ul li {
    border: none;
}

.mobile-menu li ul li a {
    font-size: 14px;
    text-transform: capitalize;
    padding: 10px 15px 8px;
}

.mobile-menu .has-children,
.mobile-menu .menu-item-has-children {
    position: relative;
}

.mobile-menu .has-children .menu-expand,
.mobile-menu .menu-item-has-children .menu-expand {
    line-height: 50;
    top: -5px;
    left: 95%;
    width: 30px;
    position: absolute;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.mobile-menu .has-children .menu-expand i,
.mobile-menu .menu-item-has-children .menu-expand i {
    display: block;
    position: relative;
    width: 10px;
    margin-top: 25px;
    border-bottom: 1px solid #252525;
    -webkit-transition: all 250ms ease-out;
    -o-transition: all 250ms ease-out;
    transition: all 250ms ease-out;
}

.mobile-menu .has-children .menu-expand i:before,
.mobile-menu .menu-item-has-children .menu-expand i:before {
    top: 0;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    border-bottom: 1px solid #252525;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.mobile-menu .has-children.active>.menu-expand i:before,
.mobile-menu .menu-item-has-children.active>.menu-expand i:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

/*----- mobile menu end -----*/

/* ----scroll to top css ----*/
.scroll-top {
    bottom: 50px;
    cursor: pointer;
    height: 50px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 50px;
    z-index: 9999;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    background-color: $theme-color;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.scroll-top i {
    line-height: 50px;
    color: #fff;
    font-size: 25px;
}

.scroll-top.not-visible {
    bottom: -50px;
    visibility: hidden;
    opacity: 0;
}

.custom-row .col-xl-3 {
    max-width: 23%;
    -webkit-flex-basis: 23%;
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .custom-row .col-xl-3 {
        max-width: 28%;
        -webkit-flex-basis: 28%;
        -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
    }

    .custom-row .col-xl-3:first-child {
        display: none;
    }
}

@media only screen and (max-width: 767.98px) {
    .custom-row .col-xl-3 {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.custom-row .col-xl-6 {
    max-width: calc(100% - 46%);
    -webkit-flex-basis: calc(100% - 46%);
    -ms-flex-preferred-size: calc(100% - 46%);
    flex-basis: calc(100% - 46%);
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .custom-row .col-xl-6 {
        max-width: calc(100% - 28%);
        -webkit-flex-basis: calc(100% - 28%);
        -ms-flex-preferred-size: calc(100% - 28%);
        flex-basis: calc(100% - 28%);
    }
}

@media only screen and (max-width: 767.98px) {
    .custom-row .col-xl-6 {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.custom-row-2 .col-xl-3 {
    max-width: 23%;
    -webkit-flex-basis: 23%;
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .custom-row-2 .col-xl-3 {
        max-width: 28%;
        -webkit-flex-basis: 28%;
        -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
    }

    .custom-row-2 .col-xl-3:first-child {
        display: none;
    }
}

@media only screen and (max-width: 767.98px) {
    .custom-row-2 .col-xl-3 {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.custom-row-2 .col-xl-6 {
    max-width: calc(100% - 46%);
    -webkit-flex-basis: calc(100% - 46%);
    -ms-flex-preferred-size: calc(100% - 46%);
    flex-basis: calc(100% - 46%);
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .custom-row-2 .col-xl-6 {
        max-width: calc(100% - 28%);
        -webkit-flex-basis: calc(100% - 28%);
        -ms-flex-preferred-size: calc(100% - 28%);
        flex-basis: calc(100% - 28%);
    }
}

@media only screen and (max-width: 767.98px) {
    .custom-row-2 .col-xl-6 {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

/*----------- header top area start -----------*/
.header-top-settings ul li {
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    margin-left: 40px;
    padding: 12px 0;
    position: relative;
    margin-left: 20px;
    padding-left: 20px;
}

.header-top-settings ul li:before {
    top: 50%;
    left: 0;
    width: 1px;
    height: 15px;
    content: "";
    position: absolute;
    background-color: #ebebeb;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-top-settings ul li span {
    cursor: auto;
    color: #fff;
    font-size: 14px;
    padding-right: 5px;
    text-transform: capitalize;
}

.header-top-settings ul li img {
    vertical-align: middle;
}

.header-top-settings ul li .dropdown-list {
    top: 100%;
    left: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    padding: 15px 15px 20px;
    width: 150px;
    z-index: 9;
    pointer-events: none;
    border-bottom: 2px solid $theme-color;
    background-color: #fff;
    -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.header-top-settings ul li .dropdown-list li {
    margin-left: 0;
    padding: 0;
}

.header-top-settings ul li .dropdown-list li a {
    color: #252525;
    font-size: 14px;
    display: block;
    padding: 5px 0 3px;
    text-transform: capitalize;
}

.header-top-settings ul li .dropdown-list li a:hover {
    color: $theme-color;
}

.header-top-settings ul li .dropdown-list li a img {
    vertical-align: inherit;
}

.header-top-settings ul li .dropdown-list li:before {
    display: none;
}

.header-top-settings ul li:hover .dropdown-list,
.header-top-settings ul li:hover .cart-list {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.header-top-settings ul li:first-child {
    margin-left: 0;
    padding-left: 0;
}

.header-top-settings ul li:first-child:before {
    display: none;
}

.header-links ul li {
    color: #fff;
    font-size: 14px;
    position: relative;
    padding-left: 20px;
    margin-left: 20px;
}

.header-links ul li a {
    color: #fff;
}

.header-links ul li a:hover {
    text-decoration: underline;
}

.header-links ul li:before {
    left: 0;
    top: 50%;
    width: 1px;
    height: 15px;
    content: "";
    position: absolute;
    background-color: #fff;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-links ul li:first-child:before {
    display: none;
}

/*------- header middile area start -------*/
.header-middle-area {
    padding: 40px 0;
}

.header-middle-area-black-text .search-box-inner .search-field {
    background-color: #fff;
}

.header-middle-area-black-text .search-box-inner .search-btn {
    color: $theme-color;
}

.header-middle-area-black-text .search-box-inner .search-btn:hover {
    color: #252525;
}

.header-middle-area-black-text .support-icon {
    color: #252525;
}

.header-middle-area-black-text .support-info {
    color: #252525;
}

.header-middle-area-black-text .header-configure-area ul li a {
    color: #252525;
}

.header-middle-area-black-text .header-configure-area ul li a:hover {
    color: $theme-color;
}

.search-box-inner {
    position: relative;
}

.search-box-inner .search-field {
    width: 100%;
    height: 48px;
    border: none;
    background-color: #fff;
    border-radius: 50px;
    padding: 0 55px 0 20px;
}

.search-box-inner .search-btn {
    width: 40px;
    height: 40px;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    color: $theme-color;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*---------- header mini cart start ----------*/
.header-configure-wrapper {
    padding-right: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.support-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .support-inner {
        margin-right: 5px;
    }
}

.support-icon {
    color: #fff;
    font-size: 30px;
    padding-right: 15px;
}

.support-info {
    color: #fff;
    font-size: 16px;
}

.support-info p {
    font-size: 14px;
    line-height: 1;
}

.support-info strong {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
}

.support-info strong a {
    color: #fff;
}

.support-info strong a:hover {
    color: #252525;
}

.header-support2 {
    text-align: right;
}

.header-support2 p {
    font-size: 14px;
    line-height: 1;
    color: #252525;
}

.header-support2 p i {
    color: $theme-color;
    font-size: 25px;
    padding-right: 5px;
    vertical-align: middle;
}

.header-support2 p a {
    color: #252525;
}

.header-support2 p a:hover {
    color: $theme-color;
}

.header-configure-area ul li {
    display: inline-block;
    font-size: 32px;
    line-height: 1;
    position: relative;
    margin-left: 30px;
}

.header-configure-area ul li a {
    color: #fff;
    position: relative;
}

.header-configure-area ul li a:hover {
    color: #252525;
}

.header-configure-area ul li a .notify {
    right: -4px;
    bottom: 2px;
    width: 18px;
    height: 18px;
    color: #fff;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    background-color: #252525;
}

.mini-cart-wrap {
    position: relative;
}

.mini-cart-wrap .cart-list-wrapper {
    width: 380px;
    top: 100%;
    right: 0;
    background-color: #fff;
    position: absolute;
    padding: 30px 20px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.mini-cart-wrap .cart-list-wrapper .cart-list {
    max-height: 201px;
    overflow-y: auto;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 0;
    margin-bottom: 20px;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-img {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 90px;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    max-width: 90px;
    position: relative;
    border: 1px solid #ebebeb;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% - 110px);
    -ms-flex: 0 0 calc(100% - 110px);
    flex: 0 0 calc(100% - 110px);
    padding-left: 10px;
    text-align: left;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info h4 {
    font-size: 15px;
    line-height: 1.4;
    font-weight: 400;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info h4 a {
    color: #252525;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info h4 a:hover {
    color: $theme-color;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info span {
    color: #252525;
    font-size: 15px;
    margin-top: 5px;
    display: block;
    line-height: 1.4;
    font-weight: 400;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info span.cart-qty {
    font-size: 12px;
    color: #555;
    font-weight: 400;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info p {
    font-size: 12px;
    color: #555;
    text-transform: capitalize;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .del-icon {
    max-width: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20px;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    font-size: 15px;
    color: #555;
    text-align: center;
    cursor: pointer;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .del-icon:hover {
    color: $theme-color;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li:last-child {
    margin-bottom: 0;
}

.mini-cart-wrap .cart-list-wrapper.mobile-cart-list {
    opacity: 0;
    visibility: hidden;
}

.mini-cart-wrap .cart-list-wrapper.mobile-cart-list.open {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.mini-cart-wrap:hover .cart-list-wrapper {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
}

ul.minicart-pricing-box {
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 15px;
    margin-top: 20px;
}

ul.minicart-pricing-box li {
    margin-bottom: 15px;
    margin-left: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

ul.minicart-pricing-box li span {
    font-size: 14px;
    color: #252525;
    text-transform: capitalize;
}

ul.minicart-pricing-box li span strong {
    color: $theme-color;
    font-weight: 500;
}

ul.minicart-pricing-box li.total span {
    font-size: 16px;
    font-weight: 500;
}

ul.minicart-pricing-box li:last-child {
    margin-bottom: 0;
}

.minicart-button a {
    color: #fff !important;
    font-size: 13px;
    display: block;
    font-weight: 500;
    line-height: 1;
    padding: 17px 0;
    background-color: #252525;
    text-transform: uppercase;
    border-radius: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.minicart-button a i {
    padding-right: 5px;
}

.minicart-button a:hover {
    color: #fff !important;
    background-color: $theme-color;
}

.minicart-button a:last-child {
    margin-bottom: 0;
}

/*----------- header settinngs area end -----------*/
/*---------- main menu style start ---------*/
.main-menu ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.main-menu ul li {
    position: relative;
    padding: 15px 5px;
}

.main-menu ul li a {
    color: #252525;
    font-size: 14px;
    padding: 4px 15px;
    font-weight: 400;
    display: block;
    text-transform: uppercase;
    border-radius: 5px;
}

.main-menu ul li a i {
    font-size: 14px;
    padding: 0 3px;
}

.main-menu ul li:first-child {
    padding-left: 0;
}

.main-menu ul li:hover>a,
.main-menu ul li.active>a {
    color: #fff;
    background-color: $theme-color;
}

.main-menu ul li ul.dropdown {
    top: 100%;
    left: 0;
    width: 220px;
    position: absolute;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
    padding: 20px 0;
    pointer-events: none;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.main-menu ul li ul.dropdown li {
    margin-right: 0;
    border-right: none;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 0;
}

.main-menu ul li ul.dropdown li a {
    color: #555;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 15px;
    text-transform: capitalize;
}

.main-menu ul li ul.dropdown li a i {
    float: right;
    padding-top: 5px;
}

.main-menu ul li ul.dropdown li:hover>a {
    color: $theme-color;
    background-color: #fff;
}

.main-menu ul li ul.dropdown li ul.dropdown {
    top: 100%;
    left: 100%;
    position: absolute;
    width: 250px;
    opacity: 0;
    visibility: hidden;
}

.main-menu ul li ul.dropdown li:hover ul.dropdown {
    top: 0;
    opacity: 1;
    visibility: visible;
}

.main-menu ul li ul.megamenu {
    width: 800px;
    -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.main-menu ul li ul.megamenu li {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
}

.main-menu ul li ul.megamenu li.mega-title>a {
    color: #252525;
    font-size: 16px;
    font-weight: 500;
}

.main-menu ul li ul.megamenu li ul li {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.main-menu ul li ul.megamenu li:hover>a {
    color: $theme-color;
}

.main-menu ul li:hover ul.dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.main-menu ul li:hover ul.megamenu {
    opacity: 1;
    visibility: visible;
}

.main-menu-style-2 ul li ul.megamenu {
    margin-left: -92px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .main-menu-style-2 ul li ul.megamenu {
        margin-left: -220px;
    }
}

.main-menu-style-1 ul li ul.megamenu {
    margin-left: -220px;
}

.main-menu.menu-style-4 ul li:hover>a,
.main-menu.menu-style-4 ul li.active>a {
    color: $theme-color;
    background-color: transparent;
}

/*---------- main menu style end ---------*/
/*------ sticky menu style start ------*/
.sticky.is-sticky {
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 9999;
    -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
    -webkit-animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
    animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
}

.sticky.is-sticky .mobile-header-top {
    display: none;
}

.sticky.is-sticky.mobile-header {
    background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .sticky.is-sticky .category-toggle-wrap {
        display: none;
    }
}

/*------ sticky menu style end ------*/
/*----- mobile menu start -----*/
.mobile-header .category-toggle {
    padding: 8px 15px;
}

.mobile-header-top {
    border-bottom: 1px solid #ebebeb;
}

.mobile-header-top .header-top-settings {
    float: none;
}

.mobile-logo {
    max-width: 140px;
    width: 100%;
}

.mobile-main-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .mobile-main-header {
        border-bottom: 1px solid #ebebeb;
    }
}

.mobile-main-header .mobile-menu-toggler {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    position: relative;
    margin-left: 30px;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a {
    color: #252525;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a:hover {
    color: $theme-color;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
    font-size: 13px;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    font-weight: 600;
    right: -8px;
    top: 0;
    position: absolute;
    background-color: $theme-color;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
    margin-left: 15px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn .off-canvas-btn {
    font-size: 30px;
    cursor: pointer;
    padding: 10px 15px;
    line-height: 1;
    padding-right: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn .off-canvas-btn:hover {
    color: $theme-color;
}

.mobile-navigation {
    overflow: hidden;
    max-height: 250px;
}

.mobile-navigation nav {
    height: 100%;
}

.mobile-menu {
    margin-top: 30px;
    height: 100%;
    overflow: auto;
    padding-right: 30px;
    margin-right: -30px;
}

@media only screen and (max-width: 479.98px) {
    .mobile-menu {
        margin-top: 15px;
    }
}

.mobile-menu li>a {
    font-size: 15px;
    color: #252525;
    text-transform: capitalize;
    line-height: 18px;
    position: relative;
    display: inline-block;
    padding: 10px 0;
}

.mobile-menu li>a:hover {
    color: $theme-color;
}

.mobile-menu li ul li {
    border: none;
}

.mobile-menu li ul li a {
    font-size: 14px;
    text-transform: capitalize;
    padding: 10px 15px 8px;
}

.mobile-menu .has-children,
.mobile-menu .menu-item-has-children {
    position: relative;
}

.mobile-menu .has-children .menu-expand,
.mobile-menu .menu-item-has-children .menu-expand {
    line-height: 50;
    top: -5px;
    left: 95%;
    width: 30px;
    position: absolute;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.mobile-menu .has-children .menu-expand i,
.mobile-menu .menu-item-has-children .menu-expand i {
    display: block;
    position: relative;
    width: 10px;
    margin-top: 25px;
    border-bottom: 1px solid #252525;
    -webkit-transition: all 250ms ease-out;
    -o-transition: all 250ms ease-out;
    transition: all 250ms ease-out;
}

.mobile-menu .has-children .menu-expand i:before,
.mobile-menu .menu-item-has-children .menu-expand i:before {
    top: 0;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    border-bottom: 1px solid #252525;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.mobile-menu .has-children.active>.menu-expand i:before,
.mobile-menu .menu-item-has-children.active>.menu-expand i:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

/*------ offcanvas widget area start ------*/
.offcanvas-widget-area {
    margin-top: auto;
    padding-bottom: 0px;
}

@media only screen and (max-width: 479.98px) {
    .offcanvas-widget-area {
        padding-bottom: 0;
    }
}

.off-canvas-contact-widget li {
    color: #555;
    font-size: 15px;
    margin-bottom: 5px;
}

.off-canvas-contact-widget li i {
    width: 20px;
}

.off-canvas-contact-widget li a {
    color: #555;
}

.off-canvas-contact-widget li a:hover {
    color: $theme-color;
}

.off-canvas-social-widget {
    margin-top: 20px;
}

.off-canvas-social-widget a {
    color: #555;
    font-size: 18px;
    display: inline-block;
    margin-right: 15px;
}

.off-canvas-social-widget a:hover {
    color: $theme-color;
}

/*------ offcanvas widget area end ------*/
.off-canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    z-index: 9999;
}

.off-canvas-wrapper.open {
    opacity: 1;
    visibility: visible;
}

.off-canvas-wrapper.open .off-canvas-inner-content {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.off-canvas-wrapper .off-canvas-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
    cursor: url("../images/icon/cancel.png"), auto;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.off-canvas-wrapper .off-canvas-inner-content {
    background-color: #fff;
    width: 300px;
    padding: 30px;
    height: 100%;
    position: relative;
    -webkit-transform: translateX(calc(-100% - 50px));
    -ms-transform: translateX(calc(-100% - 50px));
    transform: translateX(calc(-100% - 50px));
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
    .off-canvas-wrapper .off-canvas-inner-content {
        width: 260px;
        padding: 15px;
    }
}

.off-canvas-wrapper .btn-close-off-canvas {
    top: 0;
    left: 100%;
    width: 50px;
    height: 50px;
    font-size: 30px;
    background-color: $theme-color;
    color: #fff;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
    .off-canvas-wrapper .btn-close-off-canvas {
        width: 40px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }
}

.off-canvas-wrapper .btn-close-off-canvas i {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    display: block;
}

.off-canvas-wrapper .btn-close-off-canvas:hover i {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.off-canvas-wrapper .off-canvas-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: auto;
    height: 100%;
}


/*------- mobile top bar settings end -------*/
.category-dropdown-wrapper {
    height: 100%;
    -webkit-box-shadow: -1px 0px 3px 0px #cccccc;
    box-shadow: -1px 0px 3px 0px #cccccc;
}

@media only screen and (max-width: 767.98px) {
    .category-dropdown-wrapper {
        display: none;
    }
}

.category-toggle-wrap {
    position: relative;
}

.category-toggle-wrap .category-toggle {
    width: 100%;
    border: none;
    cursor: pointer;
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 18px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap .category-toggle {
        padding: 10px 0;
    }
}

.category-toggle-wrap .category-toggle i {
    font-size: 22px;
    line-height: 1;
    padding-right: 15px;
}

.category-toggle-wrap .category-toggle span {
    line-height: 1;
    margin-left: auto;
}

.category-toggle-wrap .category-toggle span i {
    font-size: 14px;
    padding-right: 0;
}

.category-toggle-wrap .category-toggle:hover {
    color: $theme-color;
}

.category-toggle-wrap .category-toggle-style_3 {
    background: #fff;
    padding: 18px 20px;
    border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap .category-toggle-style_3 {
        padding: 10px 20px;
    }
}

.category-toggle-wrap nav.category-menu {
    border-top: none;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 269px;
    position: absolute;
    z-index: 11;
    display: none;
    background-color: #fff;
}

.category-toggle-wrap nav.category-menu-style_2 {
    display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .category-toggle-wrap nav.category-menu-style_2 {
        display: none;
    }
}

.category-toggle-wrap nav.category-menu-style_3 {
    display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .category-toggle-wrap nav.category-menu-style_3 {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu {
        max-width: 100%;
        -webkit-box-shadow: 0 2px 5px 0.35px rgba(0, 0, 0, 0.15);
        box-shadow: 0 2px 5px 0.35px rgba(0, 0, 0, 0.15);
    }
}

.category-toggle-wrap nav.category-menu>ul.categories-list {
    padding: 17px 20px;
}

.category-toggle-wrap nav.category-menu>ul.categories-list-style_3 {
    padding: 8px 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul {
        max-height: 300px;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 575.98px) {
    .category-toggle-wrap nav.category-menu>ul {
        max-height: 220px;
        overflow-y: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li {
        position: relative;
    }
}

.category-toggle-wrap nav.category-menu>ul>li.menu-item-has-children {
    position: relative;
}

.category-toggle-wrap nav.category-menu>ul>li.menu-item-has-children>a::after {
    content: "\f105";
    position: absolute;
    right: 0;
    top: 50%;
    line-height: 1;
    font-family: "FontAwesome";
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li.menu-item-has-children>a::after {
        display: none;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .menu-expand {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 50px;
    width: 40px;
    line-height: 50px;
    z-index: 9;
    text-align: center;
}

.category-toggle-wrap nav.category-menu>ul>li>a {
    display: block;
    padding: 10px 0;
    line-height: 24px;
    font-size: 14px;
    color: #555;
    position: relative;
    text-transform: capitalize;
    border-bottom: 1px solid #ebebeb;
}

.category-toggle-wrap nav.category-menu>ul>li>a:hover {
    color: $theme-color;
}

.category-toggle-wrap nav.category-menu>ul>li:first-child a {
    padding-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li:first-child a {
        padding-top: 10px;
    }
}

.category-toggle-wrap nav.category-menu>ul>li:last-child a {
    padding-bottom: 0;
    border-bottom: none;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li:last-child a {
        padding-bottom: 10px;
    }
}

.category-toggle-wrap nav.category-menu>ul>li:hover .category-mega-menu {
    top: 0;
    z-index: 9;
    opacity: 1;
    visibility: visible;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu {
    position: absolute;
    left: calc(100% + 20px);
    top: 110%;
    width: 800px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 30px;
    opacity: 0;
    z-index: -9;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu {
        width: 600px;
        padding-bottom: 5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu {
        position: static;
        z-index: 9;
        opacity: 1;
        visibility: visible;
        background-color: transparent;
        padding: 0;
        width: 100%;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
        border: none;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.two-column {
    width: 400px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.two-column {
        width: 100%;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.two-column>li {
    width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.two-column>li {
        width: 100%;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.three-column {
    width: 600px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.three-column {
        width: 100%;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.three-column>li {
    width: 33.33%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.three-column>li {
        width: 100%;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li {
    float: left;
    width: 25%;
    padding: 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li {
        width: 50%;
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%;
        padding: 0;
        position: relative;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #252525;
    text-transform: uppercase;
    margin-bottom: 10px;
    position: relative;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a:before,
.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a:after {
    left: 0;
    bottom: -3px;
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #ebebeb;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a:after {
    width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a {
        padding: 10px 25px 10px 35px;
        line-height: 30px;
        font-size: 14px;
        color: #606060;
        font-weight: 400;
        position: relative;
        margin: 0;
        text-transform: inherit;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li:nth-child(odd) a:after {
    background-color: #f00;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li:nth-child(odd) a:after {
        display: none;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li:nth-child(even) a:after {
    background-color: $theme-color;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li:nth-child(even) a:after {
        display: none;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li:last-child {
    border: none;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li:last-child {
    margin-bottom: 0;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li a {
    display: block;
    font-size: 14px;
    color: #606060;
    line-height: 30px;
    padding-bottom: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li a {
        padding: 10px 25px 10px 45px;
        line-height: 30px;
        font-size: 14px;
        color: #606060;
        font-weight: 400;
        position: relative;
    }
}

@media only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li a {
        padding: 10px 25px 10px 45px;
        line-height: 30px;
        font-size: 14px;
        color: #606060;
        font-weight: 400;
        position: relative;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li a:hover {
    color: $theme-color;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li:last-child a {
    border-bottom: 0;
}


