/*---------------------------------------------------

    Version: 1.0

-----------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
        - Google Font 
        - Breadcrumb Css
    02. Header Css
        - Header Bottom Css
        - Main Menu Css
        - Shopping Cart Css
        - Mobile menu css
    03. Hero Css
    04. Search Css
    05. Product Area Css
    06. Testimonial
    07. Product Details Css
    08. Banner CSs
    09. Bloag Css
    10. Footer Css
    11. Widget Css
    12. Compare Page Css
    13. Login And Register Css
    14. My Account Page Css
    15. Error 404 Page Css
    16. Cart Page Css
    17. Checkout Page Css
    18. About Css
    19. Team Area Css
    20. Project Conunt Css  
    21. Contact css
 
--------------------------------------------------------*/
/* transition */
/* flex */
/* transform */
/* opacity */
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/*-- 
- Google Font 
---------*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800|Poppins:300,400,500,600,700,800,900");
/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  background-color: #ffffff;
  line-height: 23px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Poppins", sans-serif;
  color: #454545;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  color: #3a3a3a;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 0;
}

h1 {
  font-size: 60px;
  line-height: 72px;
}

h2 {
  font-size: 48px;
  line-height: 54px;
}

h3 {
  font-size: 34px;
  line-height: 38px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-size: 18px;
  line-height: 22px;
}

h6 {
  font-size: 16px;
  line-height: 18px;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
    line-height: 42px;
  }
  h1 br {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  h1 {
    font-size: 26px;
    line-height: 32px;
  }
  h1 br {
    display: none;
  }
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #ff5151;
}

a, button, img, input, span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

button:focus,
a:focus {
  outline: none;
  box-shadow: none;
}

.btn, .btn:focus {
  outline: 0;
  box-shadow: none !important;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .container {
    width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    width: 300px;
  }
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.light {
  font-weight: 300;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}

/*-- Container Fluid --*/
.container-fluid {
  padding: 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .container-fluid {
    padding: 0 15px;
    width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container-fluid {
    padding: 0 15px;
    width: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .footer-section .container-fluid {
    padding: 0 15px;
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-section .container-fluid {
    padding: 0 15px;
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-section .container-fluid {
    padding: 0 15px;
    width: 750px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-section .container-fluid {
    padding: 0 15px;
    width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .footer-section .container-fluid {
    padding: 0 15px;
    width: 320px;
  }
}

/*--
    - Custom Container
------------------------------------------*/
.container-ext-5 {
  max-width: 1210px;
  width: 100%;
}

.container-ext-10 {
  max-width: 1220px;
  width: 100%;
}

.container-ext-15 {
  max-width: 1230px;
  width: 100%;
}

.container-ext-20 {
  max-width: 1240px;
  width: 100%;
}

.container-ext-25 {
  max-width: 1250px;
  width: 100%;
}

.container-ext-30 {
  max-width: 1260px;
  width: 100%;
}

.container-ext-35 {
  max-width: 1270px;
  width: 100%;
}

.container-ext-40 {
  max-width: 1280px;
  width: 100%;
}

.container-ext-45 {
  max-width: 1290px;
  width: 100%;
}

.container-ext-50 {
  max-width: 1300px;
  width: 100%;
}

.container-ext-55 {
  max-width: 1310px;
  width: 100%;
}

.container-ext-60 {
  max-width: 1320px;
  width: 100%;
}

.container-ext-65 {
  max-width: 1330px;
  width: 100%;
}

.container-ext-70 {
  max-width: 1340px;
  width: 100%;
}

.container-ext-75 {
  max-width: 1350px;
  width: 100%;
}

.container-ext-80 {
  max-width: 1360px;
  width: 100%;
}

.container-ext-85 {
  max-width: 1370px;
  width: 100%;
}

.container-ext-90 {
  max-width: 1380px;
  width: 100%;
}

.container-ext-95 {
  max-width: 1390px;
  width: 100%;
}

.container-ext-100 {
  max-width: 1400px;
  width: 100%;
}

/*--
    - Custom Row
------------------------------------------*/
.row-1 {
  margin-left: -1px;
  margin-right: -1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-1 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-1 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-1 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-1 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-1 > [class*="col"] {
  padding-left: 1px;
  padding-right: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-1 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-1 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-1 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-1 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-2 {
  margin-left: -2px;
  margin-right: -2px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-2 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-2 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-2 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-2 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-2 > [class*="col"] {
  padding-left: 2px;
  padding-right: 2px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-2 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-2 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-2 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-2 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-3 {
  margin-left: -3px;
  margin-right: -3px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-3 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-3 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-3 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-3 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-3 > [class*="col"] {
  padding-left: 3px;
  padding-right: 3px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-3 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-3 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-3 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-3 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-4 {
  margin-left: -4px;
  margin-right: -4px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-4 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-4 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-4 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-4 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-4 > [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-4 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-4 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-4 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-4 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-5 {
  margin-left: -5px;
  margin-right: -5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-5 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-5 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-5 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-5 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-5 > [class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-5 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-5 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-5 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-5 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-6 {
  margin-left: -6px;
  margin-right: -6px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-6 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-6 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-6 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-6 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-6 > [class*="col"] {
  padding-left: 6px;
  padding-right: 6px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-6 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-6 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-6 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-6 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-7 {
  margin-left: -7px;
  margin-right: -7px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-7 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-7 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-7 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-7 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-7 > [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-7 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-7 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-7 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-7 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-8 {
  margin-left: -8px;
  margin-right: -8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-8 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-8 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-8 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-8 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-8 > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-8 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-8 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-8 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-8 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-9 {
  margin-left: -9px;
  margin-right: -9px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-9 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-9 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-9 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-9 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-9 > [class*="col"] {
  padding-left: 9px;
  padding-right: 9px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-9 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-9 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-9 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-9 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-10 {
  margin-left: -10px;
  margin-right: -10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-10 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-10 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-10 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-10 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-10 > [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-10 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-10 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-10 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-10 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-11 {
  margin-left: -11px;
  margin-right: -11px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-11 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-11 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-11 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-11 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-11 > [class*="col"] {
  padding-left: 11px;
  padding-right: 11px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-11 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-11 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-11 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-11 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-12 {
  margin-left: -12px;
  margin-right: -12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-12 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-12 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-12 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-12 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-12 > [class*="col"] {
  padding-left: 12px;
  padding-right: 12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-12 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-12 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-12 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-12 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-13 {
  margin-left: -13px;
  margin-right: -13px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-13 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-13 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-13 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-13 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-13 > [class*="col"] {
  padding-left: 13px;
  padding-right: 13px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-13 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-13 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-13 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-13 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-14 {
  margin-left: -14px;
  margin-right: -14px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-14 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-14 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-14 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-14 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-14 > [class*="col"] {
  padding-left: 14px;
  padding-right: 14px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-14 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-14 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-14 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-14 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-15 {
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-15 > [class*="col"] {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-15 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-15 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-15 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-15 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-16 {
  margin-left: -16px;
  margin-right: -16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-16 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-16 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-16 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-16 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-16 > [class*="col"] {
  padding-left: 16px;
  padding-right: 16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-16 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-16 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-16 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-16 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-17 {
  margin-left: -17px;
  margin-right: -17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-17 > [class*="col"] {
  padding-left: 17px;
  padding-right: 17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-17 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-17 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-17 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-17 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-18 {
  margin-left: -18px;
  margin-right: -18px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-18 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-18 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-18 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-18 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-18 > [class*="col"] {
  padding-left: 18px;
  padding-right: 18px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-18 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-18 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-18 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-18 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-19 {
  margin-left: -19px;
  margin-right: -19px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-19 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-19 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-19 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-19 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-19 > [class*="col"] {
  padding-left: 19px;
  padding-right: 19px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-19 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-19 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-19 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-19 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-20 {
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-20 > [class*="col"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-20 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-20 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-20 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-20 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-21 {
  margin-left: -21px;
  margin-right: -21px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-21 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-21 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-21 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-21 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-21 > [class*="col"] {
  padding-left: 21px;
  padding-right: 21px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-21 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-21 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-21 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-21 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-22 {
  margin-left: -22px;
  margin-right: -22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-22 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-22 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-22 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-22 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-22 > [class*="col"] {
  padding-left: 22px;
  padding-right: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-22 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-22 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-22 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-22 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-23 {
  margin-left: -23px;
  margin-right: -23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-23 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-23 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-23 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-23 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-23 > [class*="col"] {
  padding-left: 23px;
  padding-right: 23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-23 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-23 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-23 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-23 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-24 {
  margin-left: -24px;
  margin-right: -24px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-24 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-24 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-24 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-24 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-24 > [class*="col"] {
  padding-left: 24px;
  padding-right: 24px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-24 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-24 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-24 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-24 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-25 {
  margin-left: -25px;
  margin-right: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-25 > [class*="col"] {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-25 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-25 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-25 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-25 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-26 {
  margin-left: -26px;
  margin-right: -26px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-26 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-26 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-26 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-26 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-26 > [class*="col"] {
  padding-left: 26px;
  padding-right: 26px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-26 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-26 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-26 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-26 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-27 {
  margin-left: -27px;
  margin-right: -27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-27 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-27 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-27 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-27 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-27 > [class*="col"] {
  padding-left: 27px;
  padding-right: 27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-27 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-27 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-27 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-27 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-28 {
  margin-left: -28px;
  margin-right: -28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-28 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-28 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-28 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-28 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-28 > [class*="col"] {
  padding-left: 28px;
  padding-right: 28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-28 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-28 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-28 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-28 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-29 {
  margin-left: -29px;
  margin-right: -29px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-29 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-29 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-29 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-29 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-29 > [class*="col"] {
  padding-left: 29px;
  padding-right: 29px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-29 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-29 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-29 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-29 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-30 > [class*="col"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-30 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-30 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-30 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-30 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-31 {
  margin-left: -31px;
  margin-right: -31px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-31 > [class*="col"] {
  padding-left: 31px;
  padding-right: 31px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-32 {
  margin-left: -32px;
  margin-right: -32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-32 > [class*="col"] {
  padding-left: 32px;
  padding-right: 32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-33 {
  margin-left: -33px;
  margin-right: -33px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-33 > [class*="col"] {
  padding-left: 33px;
  padding-right: 33px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-34 {
  margin-left: -34px;
  margin-right: -34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-34 > [class*="col"] {
  padding-left: 34px;
  padding-right: 34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-35 > [class*="col"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-36 {
  margin-left: -36px;
  margin-right: -36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-36 > [class*="col"] {
  padding-left: 36px;
  padding-right: 36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-37 {
  margin-left: -37px;
  margin-right: -37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-37 > [class*="col"] {
  padding-left: 37px;
  padding-right: 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-38 {
  margin-left: -38px;
  margin-right: -38px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-38 > [class*="col"] {
  padding-left: 38px;
  padding-right: 38px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-39 {
  margin-left: -39px;
  margin-right: -39px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-39 > [class*="col"] {
  padding-left: 39px;
  padding-right: 39px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-40 {
  margin-left: -40px;
  margin-right: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-40 > [class*="col"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*--
    - Custom Row
------------------------------------------*/
.row-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.row-0 > [class*="col"] {
  padding-left: 0px;
  padding-right: 0px;
}

.row-1 {
  margin-left: -1px;
  margin-right: -1px;
}

.row-1 > [class*="col"] {
  padding-left: 1px;
  padding-right: 1px;
}

.row-2 {
  margin-left: -2px;
  margin-right: -2px;
}

.row-2 > [class*="col"] {
  padding-left: 2px;
  padding-right: 2px;
}

.row-3 {
  margin-left: -3px;
  margin-right: -3px;
}

.row-3 > [class*="col"] {
  padding-left: 3px;
  padding-right: 3px;
}

.row-4 {
  margin-left: -4px;
  margin-right: -4px;
}

.row-4 > [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.row-5 > [class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row-6 {
  margin-left: -6px;
  margin-right: -6px;
}

.row-6 > [class*="col"] {
  padding-left: 6px;
  padding-right: 6px;
}

.row-7 {
  margin-left: -7px;
  margin-right: -7px;
}

.row-7 > [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row-8 {
  margin-left: -8px;
  margin-right: -8px;
}

.row-8 > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.row-9 {
  margin-left: -9px;
  margin-right: -9px;
}

.row-9 > [class*="col"] {
  padding-left: 9px;
  padding-right: 9px;
}

.row-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.row-10 > [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row-11 {
  margin-left: -11px;
  margin-right: -11px;
}

.row-11 > [class*="col"] {
  padding-left: 11px;
  padding-right: 11px;
}

.row-12 {
  margin-left: -12px;
  margin-right: -12px;
}

.row-12 > [class*="col"] {
  padding-left: 12px;
  padding-right: 12px;
}

.row-13 {
  margin-left: -13px;
  margin-right: -13px;
}

.row-13 > [class*="col"] {
  padding-left: 13px;
  padding-right: 13px;
}

.row-14 {
  margin-left: -14px;
  margin-right: -14px;
}

.row-14 > [class*="col"] {
  padding-left: 14px;
  padding-right: 14px;
}

.row-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.row-15 > [class*="col"] {
  padding-left: 15px;
  padding-right: 15px;
}

.row-16 {
  margin-left: -16px;
  margin-right: -16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-16 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-16 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-16 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-16 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-16 > [class*="col"] {
  padding-left: 16px;
  padding-right: 16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-16 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-16 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-16 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-16 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-17 {
  margin-left: -17px;
  margin-right: -17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-17 > [class*="col"] {
  padding-left: 17px;
  padding-right: 17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-17 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-17 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-17 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-17 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-18 {
  margin-left: -18px;
  margin-right: -18px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-18 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-18 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-18 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-18 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-18 > [class*="col"] {
  padding-left: 18px;
  padding-right: 18px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-18 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-18 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-18 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-18 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-19 {
  margin-left: -19px;
  margin-right: -19px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-19 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-19 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-19 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-19 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-19 > [class*="col"] {
  padding-left: 19px;
  padding-right: 19px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-19 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-19 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-19 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-19 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-20 {
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-20 > [class*="col"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-20 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-20 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-20 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-20 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-21 {
  margin-left: -21px;
  margin-right: -21px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-21 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-21 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-21 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-21 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-21 > [class*="col"] {
  padding-left: 21px;
  padding-right: 21px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-21 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-21 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-21 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-21 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-22 {
  margin-left: -22px;
  margin-right: -22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-22 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-22 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-22 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-22 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-22 > [class*="col"] {
  padding-left: 22px;
  padding-right: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-22 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-22 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-22 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-22 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-23 {
  margin-left: -23px;
  margin-right: -23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-23 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-23 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-23 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-23 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-23 > [class*="col"] {
  padding-left: 23px;
  padding-right: 23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-23 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-23 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-23 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-23 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-24 {
  margin-left: -24px;
  margin-right: -24px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-24 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-24 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-24 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-24 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-24 > [class*="col"] {
  padding-left: 24px;
  padding-right: 24px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-24 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-24 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-24 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-24 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-25 {
  margin-left: -25px;
  margin-right: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-25 > [class*="col"] {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-25 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-25 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-25 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-25 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-26 {
  margin-left: -26px;
  margin-right: -26px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-26 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-26 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-26 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-26 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-26 > [class*="col"] {
  padding-left: 26px;
  padding-right: 26px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-26 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-26 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-26 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-26 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-27 {
  margin-left: -27px;
  margin-right: -27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-27 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-27 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-27 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-27 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-27 > [class*="col"] {
  padding-left: 27px;
  padding-right: 27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-27 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-27 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-27 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-27 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-28 {
  margin-left: -28px;
  margin-right: -28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-28 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-28 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-28 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-28 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-28 > [class*="col"] {
  padding-left: 28px;
  padding-right: 28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-28 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-28 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-28 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-28 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-29 {
  margin-left: -29px;
  margin-right: -29px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-29 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-29 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-29 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-29 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-29 > [class*="col"] {
  padding-left: 29px;
  padding-right: 29px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-29 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-29 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-29 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-29 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-30 > [class*="col"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-30 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-30 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-30 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-30 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-31 {
  margin-left: -31px;
  margin-right: -31px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-31 > [class*="col"] {
  padding-left: 31px;
  padding-right: 31px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-32 {
  margin-left: -32px;
  margin-right: -32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-32 > [class*="col"] {
  padding-left: 32px;
  padding-right: 32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-33 {
  margin-left: -33px;
  margin-right: -33px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-33 > [class*="col"] {
  padding-left: 33px;
  padding-right: 33px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-34 {
  margin-left: -34px;
  margin-right: -34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-34 > [class*="col"] {
  padding-left: 34px;
  padding-right: 34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-35 > [class*="col"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-36 {
  margin-left: -36px;
  margin-right: -36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-36 > [class*="col"] {
  padding-left: 36px;
  padding-right: 36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-37 {
  margin-left: -37px;
  margin-right: -37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-37 > [class*="col"] {
  padding-left: 37px;
  padding-right: 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-38 {
  margin-left: -38px;
  margin-right: -38px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-38 > [class*="col"] {
  padding-left: 38px;
  padding-right: 38px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-39 {
  margin-left: -39px;
  margin-right: -39px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-39 > [class*="col"] {
  padding-left: 39px;
  padding-right: 39px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-40 {
  margin-left: -40px;
  margin-right: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-40 > [class*="col"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-41 {
  margin-left: -41px;
  margin-right: -41px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-41 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-41 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-41 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-41 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-41 > [class*="col"] {
  padding-left: 41px;
  padding-right: 41px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-41 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-41 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-41 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-41 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-42 {
  margin-left: -42px;
  margin-right: -42px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-42 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-42 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-42 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-42 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-42 > [class*="col"] {
  padding-left: 42px;
  padding-right: 42px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-42 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-42 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-42 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-42 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-43 {
  margin-left: -43px;
  margin-right: -43px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-43 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-43 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-43 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-43 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-43 > [class*="col"] {
  padding-left: 43px;
  padding-right: 43px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-43 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-43 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-43 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-43 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-44 {
  margin-left: -44px;
  margin-right: -44px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-44 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-44 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-44 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-44 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-44 > [class*="col"] {
  padding-left: 44px;
  padding-right: 44px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-44 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-44 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-44 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-44 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-45 {
  margin-left: -45px;
  margin-right: -45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-45 > [class*="col"] {
  padding-left: 45px;
  padding-right: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-45 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-45 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-45 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-45 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-46 {
  margin-left: -46px;
  margin-right: -46px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-46 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-46 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-46 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-46 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-46 > [class*="col"] {
  padding-left: 46px;
  padding-right: 46px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-46 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-46 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-46 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-46 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-47 {
  margin-left: -47px;
  margin-right: -47px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-47 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-47 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-47 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-47 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-47 > [class*="col"] {
  padding-left: 47px;
  padding-right: 47px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-47 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-47 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-47 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-47 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-48 {
  margin-left: -48px;
  margin-right: -48px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-48 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-48 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-48 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-48 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-48 > [class*="col"] {
  padding-left: 48px;
  padding-right: 48px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-48 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-48 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-48 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-48 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-49 {
  margin-left: -49px;
  margin-right: -49px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-49 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-49 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-49 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-49 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-49 > [class*="col"] {
  padding-left: 49px;
  padding-right: 49px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-49 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-49 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-49 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-49 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-50 {
  margin-left: -50px;
  margin-right: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-50 > [class*="col"] {
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-50 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-50 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-50 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-50 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-51 {
  margin-left: -51px;
  margin-right: -51px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-51 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-51 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-51 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-51 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-51 > [class*="col"] {
  padding-left: 51px;
  padding-right: 51px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-51 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-51 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-51 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-51 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-52 {
  margin-left: -52px;
  margin-right: -52px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-52 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-52 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-52 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-52 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-52 > [class*="col"] {
  padding-left: 52px;
  padding-right: 52px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-52 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-52 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-52 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-52 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-53 {
  margin-left: -53px;
  margin-right: -53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-53 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-53 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-53 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-53 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-53 > [class*="col"] {
  padding-left: 53px;
  padding-right: 53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-53 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-53 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-53 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-53 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-54 {
  margin-left: -54px;
  margin-right: -54px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-54 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-54 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-54 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-54 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-54 > [class*="col"] {
  padding-left: 54px;
  padding-right: 54px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-54 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-54 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-54 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-54 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-55 {
  margin-left: -55px;
  margin-right: -55px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-55 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-55 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-55 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-55 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-55 > [class*="col"] {
  padding-left: 55px;
  padding-right: 55px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-55 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-55 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-55 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-55 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-56 {
  margin-left: -56px;
  margin-right: -56px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-56 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-56 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-56 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-56 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-56 > [class*="col"] {
  padding-left: 56px;
  padding-right: 56px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-56 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-56 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-56 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-56 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-57 {
  margin-left: -57px;
  margin-right: -57px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-57 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-57 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-57 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-57 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-57 > [class*="col"] {
  padding-left: 57px;
  padding-right: 57px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-57 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-57 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-57 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-57 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-58 {
  margin-left: -58px;
  margin-right: -58px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-58 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-58 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-58 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-58 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-58 > [class*="col"] {
  padding-left: 58px;
  padding-right: 58px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-58 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-58 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-58 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-58 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-59 {
  margin-left: -59px;
  margin-right: -59px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-59 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-59 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-59 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-59 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-59 > [class*="col"] {
  padding-left: 59px;
  padding-right: 59px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-59 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-59 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-59 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-59 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-60 {
  margin-left: -60px;
  margin-right: -60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-60 > [class*="col"] {
  padding-left: 60px;
  padding-right: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-60 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-60 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-60 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-60 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-61 {
  margin-left: -61px;
  margin-right: -61px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-61 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-61 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-61 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-61 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-61 > [class*="col"] {
  padding-left: 61px;
  padding-right: 61px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-61 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-61 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-61 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-61 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-62 {
  margin-left: -62px;
  margin-right: -62px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-62 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-62 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-62 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-62 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-62 > [class*="col"] {
  padding-left: 62px;
  padding-right: 62px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-62 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-62 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-62 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-62 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-63 {
  margin-left: -63px;
  margin-right: -63px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-63 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-63 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-63 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-63 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-63 > [class*="col"] {
  padding-left: 63px;
  padding-right: 63px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-63 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-63 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-63 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-63 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-64 {
  margin-left: -64px;
  margin-right: -64px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-64 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-64 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-64 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-64 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-64 > [class*="col"] {
  padding-left: 64px;
  padding-right: 64px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-64 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-64 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-64 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-64 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-65 {
  margin-left: -65px;
  margin-right: -65px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-65 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-65 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-65 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-65 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-65 > [class*="col"] {
  padding-left: 65px;
  padding-right: 65px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-65 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-65 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-65 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-65 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-66 {
  margin-left: -66px;
  margin-right: -66px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-66 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-66 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-66 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-66 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-66 > [class*="col"] {
  padding-left: 66px;
  padding-right: 66px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-66 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-66 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-66 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-66 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-67 {
  margin-left: -67px;
  margin-right: -67px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-67 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-67 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-67 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-67 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-67 > [class*="col"] {
  padding-left: 67px;
  padding-right: 67px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-67 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-67 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-67 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-67 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-68 {
  margin-left: -68px;
  margin-right: -68px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-68 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-68 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-68 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-68 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-68 > [class*="col"] {
  padding-left: 68px;
  padding-right: 68px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-68 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-68 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-68 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-68 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-69 {
  margin-left: -69px;
  margin-right: -69px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-69 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-69 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-69 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-69 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-69 > [class*="col"] {
  padding-left: 69px;
  padding-right: 69px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-69 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-69 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-69 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-69 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-70 {
  margin-left: -70px;
  margin-right: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-70 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-70 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-70 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-70 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-70 > [class*="col"] {
  padding-left: 70px;
  padding-right: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-70 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-70 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-70 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-70 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-71 {
  margin-left: -71px;
  margin-right: -71px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-71 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-71 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-71 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-71 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-71 > [class*="col"] {
  padding-left: 71px;
  padding-right: 71px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-71 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-71 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-71 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-71 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-72 {
  margin-left: -72px;
  margin-right: -72px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-72 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-72 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-72 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-72 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-72 > [class*="col"] {
  padding-left: 72px;
  padding-right: 72px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-72 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-72 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-72 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-72 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-73 {
  margin-left: -73px;
  margin-right: -73px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-73 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-73 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-73 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-73 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-73 > [class*="col"] {
  padding-left: 73px;
  padding-right: 73px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-73 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-73 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-73 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-73 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-74 {
  margin-left: -74px;
  margin-right: -74px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-74 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-74 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-74 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-74 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-74 > [class*="col"] {
  padding-left: 74px;
  padding-right: 74px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-74 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-74 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-74 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-74 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-75 {
  margin-left: -75px;
  margin-right: -75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-75 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-75 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-75 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-75 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-75 > [class*="col"] {
  padding-left: 75px;
  padding-right: 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-75 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-75 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-75 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-75 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-76 {
  margin-left: -76px;
  margin-right: -76px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-76 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-76 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-76 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-76 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-76 > [class*="col"] {
  padding-left: 76px;
  padding-right: 76px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-76 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-76 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-76 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-76 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-77 {
  margin-left: -77px;
  margin-right: -77px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-77 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-77 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-77 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-77 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-77 > [class*="col"] {
  padding-left: 77px;
  padding-right: 77px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-77 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-77 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-77 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-77 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-78 {
  margin-left: -78px;
  margin-right: -78px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-78 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-78 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-78 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-78 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-78 > [class*="col"] {
  padding-left: 78px;
  padding-right: 78px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-78 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-78 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-78 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-78 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-79 {
  margin-left: -79px;
  margin-right: -79px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-79 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-79 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-79 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-79 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-79 > [class*="col"] {
  padding-left: 79px;
  padding-right: 79px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-79 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-79 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-79 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-79 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-80 {
  margin-left: -80px;
  margin-right: -80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.row-80 > [class*="col"] {
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row-80 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-80 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-80 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .row-80 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*--
    - Paginatoin Css
---------------------------------*/
.pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .pagination-box {
    margin-top: 40px;
  }
}

.pagination-box li {
  display: inline-block;
  margin: 0 4px;
}

.pagination-box li.active a {
  color: #ffffff;
  background: #ff5151;
  border: 1px solid #ff5151;
}

.pagination-box li a {
  color: #333333;
  display: block;
  width: 40px;
  height: 40px;
  border: 1px solid #e8e8e8;
  line-height: 40px;
  text-align: center;
  color: #ff5151;
  font-size: 14px;
  border-radius: 5px;
}

.pagination-box li a:hover {
  color: #ffffff;
  background: #ff5151;
  border: 1px solid #ff5151;
}

.pagination-box li a span {
  font-size: 18px;
}

/*-- 
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-5 {
  margin-top: 5px !important;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-5 {
  margin-left: 5px !important;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

.mr-5 {
  margin-right: 5px !important;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-5 {
  padding-top: 5px !important;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-5 {
  padding-bottom: 5px !important;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

.pl-5 {
  padding-left: 5px !important;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

.pr-5 {
  padding-right: 5px !important;
}

/*-- 
    - Section Padding
-------------------------------------*/
.section-ptb {
  padding: 90px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-ptb {
    padding: 80px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-ptb {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-ptb {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 479px) {
  .section-ptb {
    padding: 60px 0;
  }
}

.section-pt {
  padding-top: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt {
    padding-top: 60px;
  }
}

.section-pt-60 {
  padding-top: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt-60 {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt-60 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt-60 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt-60 {
    padding-top: 30px;
  }
}

.section-pt-30 {
  padding-top: 30px;
}

.section-pb {
  padding-bottom: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb {
    padding-bottom: 60px;
  }
}

.section-pb-60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb-60 {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb-60 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb-60 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb-60 {
    padding-bottom: 30px;
  }
}

.section-pb-30 {
  padding-bottom: 30px;
}

.bg-image {
  background-size: cover;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 40px;
  height: 40px;
  background-color: #ff5151;
  border: 3px solid #eeeeee;
  color: #ffffff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    display: none !important;
  }
}

#scrollUp i {
  display: block;
  line-height: 34px;
  font-size: 16px;
}

#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

/*--
    - Background Color
------------------------------------------*/
.bg-gray {
  background-color: #fafafa;
}

.bg-footer {
  background: #1a1a1a;
}

img.mfp-img {
  background-color: #f6f7f8;
  padding: 0;
  margin: 40px auto;
}

.white-bg {
  background: #ffffff;
}

.black-bg {
  background: #191919;
}

/*--
    Boxed Layout
--------------------------*/
.boxed-layout {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
  margin: auto;
  max-width: 1400px;
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .boxed-layout {
    max-width: 980px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .boxed-layout {
    max-width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .boxed-layout {
    max-width: 560px;
  }
}

/*--
    - Social Color
------------------------------------------*/
.facebook {
  background-color: #3B5997;
  color: #ffffff;
}

.facebook:hover {
  background-color: #3B5997;
  color: #ffffff;
}

.twitter {
  background-color: #1DA1F2;
  color: #ffffff;
}

.twitter:hover {
  background-color: #1DA1F2;
  color: #ffffff;
}

.linkedin {
  background-color: #0077B5;
  color: #ffffff;
}

.linkedin:hover {
  background-color: #0077B5;
  color: #ffffff;
}

.pinterest {
  background-color: #BD081C;
  color: #ffffff;
}

.pinterest:hover {
  background-color: #BD081C;
  color: #ffffff;
}

.google-plus {
  background-color: #D11718;
  color: #ffffff;
}

.google-plus:hover {
  background-color: #D11718;
  color: #ffffff;
}

.behance {
  background-color: #005CFF;
  color: #ffffff;
}

.behance:hover {
  background-color: #005CFF;
  color: #ffffff;
}

.dribbble {
  background-color: #E84C88;
  color: #ffffff;
}

.dribbble:hover {
  background-color: #E84C88;
  color: #ffffff;
}

.github {
  background-color: #323131;
  color: #ffffff;
}

.github:hover {
  background-color: #323131;
  color: #ffffff;
}

.instagram {
  background-color: #DC3472;
  color: #ffffff;
}

.instagram:hover {
  background-color: #DC3472;
  color: #ffffff;
}

.medium {
  background-color: #00AB6C;
  color: #ffffff;
}

.medium:hover {
  background-color: #00AB6C;
  color: #ffffff;
}

.reddit {
  background-color: #FF4501;
  color: #ffffff;
}

.reddit:hover {
  background-color: #FF4501;
  color: #ffffff;
}

.skype {
  background-color: #00AFF0;
  color: #ffffff;
}

.skype:hover {
  background-color: #00AFF0;
  color: #ffffff;
}

.slack {
  background-color: #63C1A0;
  color: #ffffff;
}

.slack:hover {
  background-color: #63C1A0;
  color: #ffffff;
}

.snapchat {
  background-color: #FFFC00;
  color: #ffffff;
}

.snapchat:hover {
  background-color: #FFFC00;
  color: #ffffff;
}

.tumblr {
  background-color: #01273A;
  color: #ffffff;
}

.tumblr:hover {
  background-color: #01273A;
  color: #ffffff;
}

.vimeo {
  background-color: #00ADEF;
  color: #ffffff;
}

.vimeo:hover {
  background-color: #00ADEF;
  color: #ffffff;
}

.youtube {
  background-color: #FE0002;
  color: #ffffff;
}

.youtube:hover {
  background-color: #FE0002;
  color: #ffffff;
}

/*-- Tab Content & Pane Fix --*/
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*--
    - Product Full Width
------------------------------------------*/
.full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
  flex: 1 0 450px;
  max-width: 450px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
    flex: 1 0 350px;
    max-width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
    flex: 1 0 350px;
    max-width: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
  flex: 0 0 calc(100% - 450px);
  max-width: calc(100% - 450px);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
    flex: 0 0 calc(100% - 350px);
    max-width: calc(100% - 350px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
    flex: 0 0 calc(100% - 350px);
    max-width: calc(100% - 350px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .full-width-product-wrap.desktop-full-width > [class*="col-"]:nth-child(1), .full-width-product-wrap.desktop-full-width > [class*="col"]:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .full-width-product-wrap.desktop-full-width > [class*="col-"]:nth-child(2), .full-width-product-wrap.desktop-full-width > [class*="col"]:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .laptop-3-column > [class*="col-"], .laptop-3-column > [class*="col"] {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

/*-- 
    - Page Pagination
------------------------------------------*/
.page-pagination {
  background: #fff6f6;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
}

.page-pagination a {
  color: #ff5151;
}

.page-pagination a:hover {
  color: #222;
}

.page-pagination ul {
  display: flex;
  justify-content: center;
}

.page-pagination li {
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  color: #3a3a3a;
  text-align: center;
  margin: 2px 3px;
}

.page-pagination li a {
  color: #333;
  padding: 10px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 479px) {
  .page-pagination li {
    font-size: 14px;
  }
  .page-pagination li a {
    padding: 9px;
    width: 35px;
    height: 35px;
  }
  .page-pagination li a i {
    line-height: 24px;
  }
}

.page-pagination li:hover a {
  color: #ff5151;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.page-pagination li.active a {
  color: #ff5151;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/*-- Category Page Title --*/
.category-page-title h4 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #3a3a3a;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

/*--
    - Button
------------------------------------------*/
.btn, .btn-2 {
  border: 1px solid #626262;
  line-height: 24px;
  padding: 10px 40px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: #3a3a3a;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btn:focus, .btn-2:focus {
  outline: none;
}

.btn::before, .btn-2::before {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 150%;
  background-color: #3a3a3a;
  transition: all 0.3s ease 0s;
  content: "";
  transform: translateX(-50%) translateY(-50%) rotate(-30deg);
  opacity: 0;
  z-index: -1;
}

.btn:hover, .btn-2:hover {
  color: #ff5151;
}

.btn:hover::before, .btn-2:hover::before {
  height: 300%;
  opacity: 1;
}

.btn.hover-theme::before, .btn-2.hover-theme::before {
  background-color: #ff5151;
}

.btn.hover-theme:hover, .btn-2.hover-theme:hover {
  border-color: #ff5151;
  color: #3a3a3a;
}

.btn.hover-theme:hover::before, .btn-2.hover-theme:hover::before {
  height: 300%;
  opacity: 1;
}

.btn.btn-circle, .btn-2.btn-circle {
  border-radius: 50px;
}

.btn.btn-radius, .btn-2.btn-radius {
  border-radius: 5px;
}

.btn.btn-square, .btn-2.btn-square {
  border-radius: 0;
}

.btn.btn-text, .btn-2.btn-text {
  border-radius: 0;
  padding: 0;
  line-height: 20px;
  border: none;
}

.btn.btn-text::before, .btn-2.btn-text::before {
  display: none;
}

.btn.btn-text.hover-theme:hover, .btn-2.btn-text.hover-theme:hover {
  color: #ff5151;
}

.btn.btn-underline, .btn-2.btn-underline {
  text-decoration: underline;
}

.btn.btn-large, .btn-2.btn-large {
  padding: 15px 40px;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .btn.btn-large, .btn-2.btn-large {
    font-size: 12px;
    padding: 7px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .btn.btn-large, .btn-2.btn-large {
    font-size: 11px;
    padding: 4px 15px;
    margin-top: 15px;
  }
}

.btn.btn-medium, .btn-2.btn-medium {
  padding: 7px 20px;
  font-size: 12px;
}

@media only screen and (max-width: 767px) {
  .btn.btn-medium, .btn-2.btn-medium {
    font-size: 12px;
    padding: 7px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .btn.btn-medium, .btn-2.btn-medium {
    font-size: 11px;
    padding: 4px 15px;
    margin-top: 15px;
  }
}

.btn.btn-small, .btn-2.btn-small {
  padding: 4px 15px;
  font-size: 11px;
}

@media only screen and (max-width: 767px) {
  .btn, .btn-2 {
    font-size: 12px;
    padding: 7px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .btn, .btn-2 {
    font-size: 11px;
    padding: 4px 15px;
  }
}

.btn-2 {
  padding: 8px 30px;
}

/*--
    - Blockquote
------------------------------------------*/
.blockquote {
  padding: 30px 20px;
  position: relative;
  color: #ffffff;
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .blockquote {
    padding: 30px 15px 0 15px;
  }
}

@media only screen and (max-width: 479px) {
  .blockquote {
    padding: 20px;
  }
}

.blockquote p {
  font-size: 14px;
  color: #ff5151;
}

/*--
- Section Title
----------------------------*/
.section-title {
  margin-bottom: 20px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  margin: -5px 0 0 0;
  line-height: 1.6;
}

@media only screen and (max-width: 479px) {
  .section-title h2 {
    font-size: 26px;
    line-height: 30px;
  }
}

.section-title h2 span {
  color: #ff5151;
}

.section-title p {
  max-width: 640px;
  margin-top: 16px;
  margin-bottom: 0;
}

.section-title.text-center p {
  margin-left: auto;
  margin-right: auto;
}

.section-title.text-right p {
  margin-left: auto;
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .section-title {
    margin-bottom: 30px;
  }
}

.title-2 h4 {
  color: #ff5151;
  font-weight: 600;
}

.title-2 h3 {
  color: #ff5151;
  font-weight: 600;
}

/*--
    - Breadcrumb Css
------------------------------------------*/
.breadcrumb-area {
  background: url("../images/bg/breadcrumb-bg.jpg") repeat scroll 0 0;
  text-align: center;
}

.breadcrumb-area h2 {
  font-size: 30px;
  font-weight: 600;
  margin-top: -5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area h2 {
    font-size: 24px;
  }
}

.breadcrumb-list .breadcrumb-item {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 1;
  display: inline-block;
}

.breadcrumb-list .breadcrumb-item.active {
  color: #ff5151;
}

.breadcrumb-list .breadcrumb-item a:hover {
  color: #ff5151;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/*--
    Sidebar Widget Css
----------------------------------*/
.widget-title {
  margin-bottom: 20px;
}

.widget-title h4 {
  font-weight: 600;
}

.widget-search-form {
  position: relative;
  margin-top: 20px;
}

.widget-search-form input {
  border-bottom: 1px solid #ddd;
  position: relative;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  padding: 0 40px 10px 10px;
}

.widget-search-form button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
}

.widget-search-form button:hover {
  color: #ff5151;
}

.widget-related-wrap .single-related {
  position: relative;
  padding-left: 115px;
  min-height: 105px;
  margin-bottom: 20px;
  padding-top: 10px;
  overflow: hidden;
}

.widget-related-wrap .single-related:last-child {
  margin-bottom: 0;
}

.widget-related-wrap .single-related-image {
  position: absolute;
  left: 0;
  top: 5px;
  overflow: hidden;
  max-width: 100px;
}

.widget-related-wrap .single-related-contents h5 {
  font-weight: 600;
  font-size: 16px;
}

.widget-related-wrap .single-related-contents p {
  font-weight: 13px;
}

.archives li {
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.archives li::before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  background: #222;
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.archives li a span {
  margin-left: 30px;
}

.justify-content-between [class*="col"], .justify-content-between [class*="col-"] {
  flex-grow: 0;
}

.black-ovelay {
  position: relative;
}

.black-ovelay::before {
  background: rgba(0, 0, 0, 0.8);
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}

/*-- Overlay styles --*/
[data-overlay],
[data-secondary-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative;
}

[data-overlay] > div,
[data-overlay] > *,
[data-secondary-overlay] > div,
[data-secondary-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-secondary-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

[data-overlay]:before {
  background-color: #ff5151;
}

[data-secondary-overlay]:before {
  background-color: #6564db;
}

[data-black-overlay]:before {
  background-color: #000000;
}

[data-white-overlay]:before {
  background-color: #ffffff;
}

[data-overlay="1"]:before,
[data-secondary-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before,
[data-secondary-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before,
[data-secondary-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before,
[data-secondary-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before,
[data-secondary-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before,
[data-secondary-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before,
[data-secondary-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before,
[data-secondary-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before,
[data-secondary-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before,
[data-secondary-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1;
}

/*--
    02. Header Css
-----------------------*/
.transparent-header {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 111;
  margin: auto;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.header-top-inner {
  border-bottom: 1px solid #ddd;
}

.top-info {
  padding: 10px 0;
}

.top-info li {
  display: inline-block;
  margin-right: 15px;
  padding-right: 15px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top-info li {
    margin-right: 12px;
    padding-right: 12px;
  }
}

.top-info li::after {
  height: 15px;
  width: 1px;
  content: '';
  position: absolute;
  background: #000000;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%) rotate(20deg);
}

.top-info li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.top-info li:last-child::after {
  display: none;
}

.social-top {
  padding: 10px 0;
}

.social-top ul li {
  display: inline-block;
  margin-right: 20px;
}

.social-top ul li a {
  font-size: 16px;
}

/*--
    - Header Bottom Css
-------------------------------*/
.logo {
  margin: 40px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo {
    margin: 26px 0;
  }
}

@media only screen and (max-width: 479px) {
  .logo {
    width: 125px;
    margin: 25px 0;
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    margin: 25px 0;
  }
}

.main-menu-area ul > li {
  display: inline-block;
  position: relative;
  padding: 0 20px;
}

.main-menu-area ul > li > a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 30px 0;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main-menu-area ul > li > a::before {
  content: "";
  background: #ff5151;
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main-menu-area ul > li:first-child {
  padding-left: 0;
}

.main-menu-area ul > li:hover > a::before {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  width: 100%;
}

.main-menu-area ul > li:hover .sub-menu, .main-menu-area ul > li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  top: 100%;
}

/*--
    - Main Menu Css
---------------------*/
.main-menu-area .sub-menu {
  background: #ffffff;
  left: 0;
  padding: 15px;
  position: absolute;
  text-align: left;
  width: 180px;
  z-index: 99;
  top: 120%;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.main-menu-area .sub-menu > li {
  padding: 0;
  display: block;
}

.main-menu-area .sub-menu > li:first-child {
  margin-bottom: 0;
}

.main-menu-area .sub-menu > li > a {
  padding: 0;
  font-weight: 400;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.main-menu-area .sub-menu > li > a::before {
  display: none;
}

.main-menu-area .mega-menu {
  background: #ffffff;
  left: 0;
  padding: 30px 20px;
  position: absolute;
  text-align: left;
  width: 640px;
  z-index: 99;
  top: 120%;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0, 0, 0, 0.1 rgba(0, 0, 0, 0.1);
}

.main-menu-area .mega-menu > li {
  width: 33.333%;
  float: left;
  padding: 0;
}

.main-menu-area .mega-menu > li > a {
  padding: 0;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.main-menu-area .mega-menu > li > a::before {
  display: none;
}

.main-menu-area .mega-menu > li > a::after {
  content: "";
  background: #ff5151;
  width: 40px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main-menu-area .mega-menu > li ul li {
  display: block;
  padding: 0;
}

.main-menu-area .mega-menu > li ul li a {
  padding: 0;
  text-transform: capitalize;
  display: block;
  font-weight: 400;
  margin-top: 8px;
}

.main-menu-area .mega-menu > li ul li a::before {
  display: none;
}

.trigger-search {
  display: inline-block;
}

.main-search-active {
  background: rgba(0, 0, 0, 0.92) none repeat scroll 0 0;
  width: 100%;
  min-height: 100vh;
  padding: 32px 46px 39px;
  position: fixed;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  opacity: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 9999;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.sidebar-search-icon {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 0 none;
  border-radius: 100%;
  color: #ffffff;
  font-size: 30px;
  line-height: 1;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
}

.sidebar-search-icon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  font-size: 30px;
  line-height: 1;
  transition: all 0.3s ease 0s;
}

.sidebar-search-icon button:hover {
  color: #ff5151;
}

.sidebar-search-input {
  -ms-flex-item-align: center;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.sidebar-search-input .form-search {
  position: relative;
}

.sidebar-search-input .form-search input {
  background-color: rgba(0, 0, 0, 0);
  border-color: #dddddd;
  border-style: solid;
  border-width: 0 0 1px;
  color: #ffffff;
  display: block;
  font-size: 16px;
  height: 70px;
  line-height: 70px;
  padding: 15px 50px 15px 0;
  width: 800px;
}

.sidebar-search-input .form-search input::placeholder {
  color: #fff;
}

.sidebar-search-input .form-search input::-webkit-input-placeholder {
  color: #fff;
}

.sidebar-search-input .form-search input::-moz-placeholder {
  color: #fff;
}

.sidebar-search-input .form-search input::-ms-input-placeholder {
  color: #fff;
}

.sidebar-search-input .form-search input::placeholder {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-search-input .form-search input {
    width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-search-input .form-search input {
    width: 400px;
  }
}

@media only screen and (max-width: 479px) {
  .sidebar-search-input .form-search input {
    width: 280px;
  }
}

.sidebar-search-input .form-search button {
  background-color: rgba(0, 0, 0, 0);
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 25px;
  transition: all 0.3s ease 0s;
  width: 40px;
}

.sidebar-search-input .form-search button:hover {
  color: #ff5151;
}

.main-search-active.inside {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
}

/*--
    - Right Blok Box Css
---------------------------*/
.right-blok-box {
  justify-content: flex-end;
  margin: 0px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .right-blok-box {
    margin: 34px 50px 24px 0;
  }
}

@media only screen and (max-width: 767px) {
  .right-blok-box {
    margin: 34px 0px 24px 0;
  }
}

@media only screen and (max-width: 479px) {
  .right-blok-box {
    margin: 28px 4px 24px 0;
  }
}

.right-blok-box a {
  font-size: 24px;
}

@media only screen and (max-width: 479px) {
  .right-blok-box a {
    font-size: 20px;
  }
}

.user-wrap {
  padding: 0 20px;
}

@media only screen and (max-width: 479px) {
  .user-wrap {
    padding: 0 15px;
  }
}

/*-- 
    - Shopping Cart Css
----------------------------*/
.shopping-cart-wrap {
  position: relative;
}

.shopping-cart-wrap ul.mini-cart {
  position: absolute;
  width: 300px;
  background: #fff;
  box-shadow: 0 0 3px 2px #dddddd;
  right: 0;
  top: 130%;
  padding: 15px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  transition: all 0.3s ease-in-out;
}

.shopping-cart-wrap ul.mini-cart .cart-item {
  display: flex;
  padding-bottom: 15px;
  position: relative;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-image {
  border: 1px solid #f1f1f1;
  display: block;
  width: 100px;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title {
  padding-left: 15px;
  width: 60%;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title h4 {
  font-size: 15px;
  font-weight: 500;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .quantity {
  display: block;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .price-box {
  color: #ff5151;
  font-weight: 500;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .price-box .old-price {
  color: #666;
  text-decoration: line-through;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .remove_from_cart {
  margin-top: 10px;
  position: absolute;
  right: 0;
  font-size: 14px;
}

.shopping-cart-wrap ul.mini-cart .subtotal-titles .subtotal-titles {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.shopping-cart-wrap ul.mini-cart .subtotal-titles .subtotal-titles h3 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .shopping-cart-wrap ul.mini-cart {
    right: -30px;
    width: 280px;
  }
}

.shopping-cart-wrap:hover ul.mini-cart {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  top: 100%;
}

#cart-total {
  background: #ff5151;
  border-radius: 100%;
  color: #ffffff;
  float: right;
  font-size: 10px;
  font-weight: 500;
  height: 18px;
  line-height: 18px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  top: -2px;
  width: 18px;
  right: 8px;
}

.mini-cart-btns .cart-btns {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  width: 100%;
}

.mini-cart-btns .cart-btns a {
  background: #ff5151;
  border: 2px solid #ff5151;
  border-radius: 50px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 40px;
  line-height: 36px;
  padding: 0 25px;
  text-align: center;
  text-transform: uppercase;
}

.mini-cart-btns .cart-btns a:hover {
  background: #000000;
  border: 2px solid #000000;
}

/*---------------------------
    - Mobile menu css
-----------------------------*/
.mobile-header .category-toggle {
  padding: 8px 15px;
}

.mobile-header-top {
  border-bottom: 1px solid #ebebeb;
}

.mobile-header-top .header-top-settings {
  float: none;
}

.mobile-logo {
  max-width: 140px;
  width: 100%;
}

.mobile-main-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .mobile-main-header {
    border-bottom: 1px solid #ebebeb;
  }
}

.mobile-main-header .mobile-menu-toggler {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  position: relative;
  margin-left: 30px;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a {
  color: #252525;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a:hover {
  color: #ff5151;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
  font-size: 13px;
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  right: -8px;
  top: 0;
  position: absolute;
  background-color: #ff5151;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
  margin-left: 15px;
}

.mobile-menu-btn .off-canvas-btn {
  font-size: 23px;
  cursor: pointer;
  padding: 0px 10px;
  line-height: 1;
  padding-right: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn .off-canvas-btn:hover {
  color: #ff5151;
}

.mobile-navigation {
  overflow: hidden;
  max-height: 250px;
}

.mobile-navigation nav {
  height: 100%;
}

.mobile-menu {
  margin-top: 30px;
  height: 100%;
  overflow: auto;
  padding-right: 30px;
  margin-right: -30px;
}

@media only screen and (max-width: 479.98px) {
  .mobile-menu {
    margin-top: 15px;
  }
}

.mobile-menu li > a {
  font-size: 15px;
  color: #252525;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
}

.mobile-menu li > a:hover {
  color: #ff5151;
}

.mobile-menu li ul li {
  border: none;
}

.mobile-menu li ul li a {
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px 15px 8px;
}

.mobile-menu .has-children,
.mobile-menu .menu-item-has-children {
  position: relative;
}

.mobile-menu .has-children .menu-expand,
.mobile-menu .menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-menu .has-children .menu-expand i,
.mobile-menu .menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #252525;
  -webkit-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

.mobile-menu .has-children .menu-expand i:before,
.mobile-menu .menu-item-has-children .menu-expand i:before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-bottom: 1px solid #252525;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.mobile-menu .has-children.active > .menu-expand i:before,
.mobile-menu .menu-item-has-children.active > .menu-expand i:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

/*----- mobile menu end -----*/
/* ----scroll to top css ----*/
.scroll-top {
  bottom: 50px;
  cursor: pointer;
  height: 50px;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 50px;
  z-index: 9999;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  background-color: #ff5151;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.scroll-top i {
  line-height: 50px;
  color: #fff;
  font-size: 25px;
}

.scroll-top.not-visible {
  bottom: -50px;
  visibility: hidden;
  opacity: 0;
}

.custom-row .col-xl-3 {
  max-width: 23%;
  -webkit-flex-basis: 23%;
  -ms-flex-preferred-size: 23%;
  flex-basis: 23%;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
  .custom-row .col-xl-3 {
    max-width: 28%;
    -webkit-flex-basis: 28%;
    -ms-flex-preferred-size: 28%;
    flex-basis: 28%;
  }
  .custom-row .col-xl-3:first-child {
    display: none;
  }
}

@media only screen and (max-width: 767.98px) {
  .custom-row .col-xl-3 {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.custom-row .col-xl-6 {
  max-width: calc(100% - 46%);
  -webkit-flex-basis: calc(100% - 46%);
  -ms-flex-preferred-size: calc(100% - 46%);
  flex-basis: calc(100% - 46%);
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
  .custom-row .col-xl-6 {
    max-width: calc(100% - 28%);
    -webkit-flex-basis: calc(100% - 28%);
    -ms-flex-preferred-size: calc(100% - 28%);
    flex-basis: calc(100% - 28%);
  }
}

@media only screen and (max-width: 767.98px) {
  .custom-row .col-xl-6 {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.custom-row-2 .col-xl-3 {
  max-width: 23%;
  -webkit-flex-basis: 23%;
  -ms-flex-preferred-size: 23%;
  flex-basis: 23%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .custom-row-2 .col-xl-3 {
    max-width: 28%;
    -webkit-flex-basis: 28%;
    -ms-flex-preferred-size: 28%;
    flex-basis: 28%;
  }
  .custom-row-2 .col-xl-3:first-child {
    display: none;
  }
}

@media only screen and (max-width: 767.98px) {
  .custom-row-2 .col-xl-3 {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.custom-row-2 .col-xl-6 {
  max-width: calc(100% - 46%);
  -webkit-flex-basis: calc(100% - 46%);
  -ms-flex-preferred-size: calc(100% - 46%);
  flex-basis: calc(100% - 46%);
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .custom-row-2 .col-xl-6 {
    max-width: calc(100% - 28%);
    -webkit-flex-basis: calc(100% - 28%);
    -ms-flex-preferred-size: calc(100% - 28%);
    flex-basis: calc(100% - 28%);
  }
}

@media only screen and (max-width: 767.98px) {
  .custom-row-2 .col-xl-6 {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

/*----------- header top area start -----------*/
.header-top-settings ul li {
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  margin-left: 40px;
  padding: 12px 0;
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.header-top-settings ul li:before {
  top: 50%;
  left: 0;
  width: 1px;
  height: 15px;
  content: "";
  position: absolute;
  background-color: #ebebeb;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-top-settings ul li span {
  cursor: auto;
  color: #fff;
  font-size: 14px;
  padding-right: 5px;
  text-transform: capitalize;
}

.header-top-settings ul li img {
  vertical-align: middle;
}

.header-top-settings ul li .dropdown-list {
  top: 100%;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 15px 15px 20px;
  width: 150px;
  z-index: 9;
  pointer-events: none;
  border-bottom: 2px solid #ff5151;
  background-color: #fff;
  -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.header-top-settings ul li .dropdown-list li {
  margin-left: 0;
  padding: 0;
}

.header-top-settings ul li .dropdown-list li a {
  color: #252525;
  font-size: 14px;
  display: block;
  padding: 5px 0 3px;
  text-transform: capitalize;
}

.header-top-settings ul li .dropdown-list li a:hover {
  color: #ff5151;
}

.header-top-settings ul li .dropdown-list li a img {
  vertical-align: inherit;
}

.header-top-settings ul li .dropdown-list li:before {
  display: none;
}

.header-top-settings ul li:hover .dropdown-list,
.header-top-settings ul li:hover .cart-list {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.header-top-settings ul li:first-child {
  margin-left: 0;
  padding-left: 0;
}

.header-top-settings ul li:first-child:before {
  display: none;
}

.header-links ul li {
  color: #fff;
  font-size: 14px;
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
}

.header-links ul li a {
  color: #fff;
}

.header-links ul li a:hover {
  text-decoration: underline;
}

.header-links ul li:before {
  left: 0;
  top: 50%;
  width: 1px;
  height: 15px;
  content: "";
  position: absolute;
  background-color: #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-links ul li:first-child:before {
  display: none;
}

/*------- header middile area start -------*/
.header-middle-area {
  padding: 40px 0;
}

.header-middle-area-black-text .search-box-inner .search-field {
  background-color: #fff;
}

.header-middle-area-black-text .search-box-inner .search-btn {
  color: #ff5151;
}

.header-middle-area-black-text .search-box-inner .search-btn:hover {
  color: #252525;
}

.header-middle-area-black-text .support-icon {
  color: #252525;
}

.header-middle-area-black-text .support-info {
  color: #252525;
}

.header-middle-area-black-text .header-configure-area ul li a {
  color: #252525;
}

.header-middle-area-black-text .header-configure-area ul li a:hover {
  color: #ff5151;
}

.search-box-inner {
  position: relative;
}

.search-box-inner .search-field {
  width: 100%;
  height: 48px;
  border: none;
  background-color: #fff;
  border-radius: 50px;
  padding: 0 55px 0 20px;
}

.search-box-inner .search-btn {
  width: 40px;
  height: 40px;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  color: #ff5151;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*---------- header mini cart start ----------*/
.header-configure-wrapper {
  padding-right: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.support-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .support-inner {
    margin-right: 5px;
  }
}

.support-icon {
  color: #fff;
  font-size: 30px;
  padding-right: 15px;
}

.support-info {
  color: #fff;
  font-size: 16px;
}

.support-info p {
  font-size: 14px;
  line-height: 1;
}

.support-info strong {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

.support-info strong a {
  color: #fff;
}

.support-info strong a:hover {
  color: #252525;
}

.header-support2 {
  text-align: right;
}

.header-support2 p {
  font-size: 14px;
  line-height: 1;
  color: #252525;
}

.header-support2 p i {
  color: #ff5151;
  font-size: 25px;
  padding-right: 5px;
  vertical-align: middle;
}

.header-support2 p a {
  color: #252525;
}

.header-support2 p a:hover {
  color: #ff5151;
}

.header-configure-area ul li {
  display: inline-block;
  font-size: 32px;
  line-height: 1;
  position: relative;
  margin-left: 30px;
}

.header-configure-area ul li a {
  color: #fff;
  position: relative;
}

.header-configure-area ul li a:hover {
  color: #252525;
}

.header-configure-area ul li a .notify {
  right: -4px;
  bottom: 2px;
  width: 18px;
  height: 18px;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  background-color: #252525;
}

.mini-cart-wrap {
  position: relative;
}

.mini-cart-wrap .cart-list-wrapper {
  width: 380px;
  top: 100%;
  right: 0;
  background-color: #fff;
  position: absolute;
  padding: 30px 20px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.mini-cart-wrap .cart-list-wrapper .cart-list {
  max-height: 201px;
  overflow-y: auto;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 0;
  margin-bottom: 20px;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-img {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 90px;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  max-width: 90px;
  position: relative;
  border: 1px solid #ebebeb;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(100% - 110px);
  -ms-flex: 0 0 calc(100% - 110px);
  flex: 0 0 calc(100% - 110px);
  padding-left: 10px;
  text-align: left;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info h4 {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 400;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info h4 a {
  color: #252525;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info h4 a:hover {
  color: #ff5151;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info span {
  color: #252525;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  line-height: 1.4;
  font-weight: 400;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info span.cart-qty {
  font-size: 12px;
  color: #555;
  font-weight: 400;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .cart-info p {
  font-size: 12px;
  color: #555;
  text-transform: capitalize;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .del-icon {
  max-width: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20px;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  font-size: 15px;
  color: #555;
  text-align: center;
  cursor: pointer;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li .del-icon:hover {
  color: #ff5151;
}

.mini-cart-wrap .cart-list-wrapper .cart-list li:last-child {
  margin-bottom: 0;
}

.mini-cart-wrap .cart-list-wrapper.mobile-cart-list {
  opacity: 0;
  visibility: hidden;
}

.mini-cart-wrap .cart-list-wrapper.mobile-cart-list.open {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.mini-cart-wrap:hover .cart-list-wrapper {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
}

ul.minicart-pricing-box {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-top: 15px;
  margin-top: 20px;
}

ul.minicart-pricing-box li {
  margin-bottom: 15px;
  margin-left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

ul.minicart-pricing-box li span {
  font-size: 14px;
  color: #252525;
  text-transform: capitalize;
}

ul.minicart-pricing-box li span strong {
  color: #ff5151;
  font-weight: 500;
}

ul.minicart-pricing-box li.total span {
  font-size: 16px;
  font-weight: 500;
}

ul.minicart-pricing-box li:last-child {
  margin-bottom: 0;
}

.minicart-button a {
  color: #fff !important;
  font-size: 13px;
  display: block;
  font-weight: 500;
  line-height: 1;
  padding: 17px 0;
  background-color: #252525;
  text-transform: uppercase;
  border-radius: 40px;
  text-align: center;
  margin-bottom: 10px;
}

.minicart-button a i {
  padding-right: 5px;
}

.minicart-button a:hover {
  color: #fff !important;
  background-color: #ff5151;
}

.minicart-button a:last-child {
  margin-bottom: 0;
}

/*----------- header settinngs area end -----------*/
/*---------- main menu style start ---------*/
.main-menu ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main-menu ul li {
  position: relative;
  padding: 15px 5px;
}

.main-menu ul li a {
  color: #252525;
  font-size: 14px;
  padding: 4px 15px;
  font-weight: 400;
  display: block;
  text-transform: uppercase;
  border-radius: 5px;
}

.main-menu ul li a i {
  font-size: 14px;
  padding: 0 3px;
}

.main-menu ul li:first-child {
  padding-left: 0;
}

.main-menu ul li:hover > a,
.main-menu ul li.active > a {
  color: #fff;
  background-color: #ff5151;
}

.main-menu ul li ul.dropdown {
  top: 100%;
  left: 0;
  width: 220px;
  position: absolute;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  padding: 20px 0;
  pointer-events: none;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.main-menu ul li ul.dropdown li {
  margin-right: 0;
  border-right: none;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  padding: 0;
}

.main-menu ul li ul.dropdown li a {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 15px;
  text-transform: capitalize;
}

.main-menu ul li ul.dropdown li a i {
  float: right;
  padding-top: 5px;
}

.main-menu ul li ul.dropdown li:hover > a {
  color: #ff5151;
  background-color: #fff;
}

.main-menu ul li ul.dropdown li ul.dropdown {
  top: 100%;
  left: 100%;
  position: absolute;
  width: 250px;
  opacity: 0;
  visibility: hidden;
}

.main-menu ul li ul.dropdown li:hover ul.dropdown {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.main-menu ul li ul.megamenu {
  width: 800px;
  -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.main-menu ul li ul.megamenu li {
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}

.main-menu ul li ul.megamenu li.mega-title > a {
  color: #252525;
  font-size: 16px;
  font-weight: 500;
}

.main-menu ul li ul.megamenu li ul li {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.main-menu ul li ul.megamenu li:hover > a {
  color: #ff5151;
}

.main-menu ul li:hover ul.dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.main-menu ul li:hover ul.megamenu {
  opacity: 1;
  visibility: visible;
}

.main-menu-style-2 ul li ul.megamenu {
  margin-left: -92px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .main-menu-style-2 ul li ul.megamenu {
    margin-left: -220px;
  }
}

.main-menu-style-1 ul li ul.megamenu {
  margin-left: -220px;
}

.main-menu.menu-style-4 ul li:hover > a,
.main-menu.menu-style-4 ul li.active > a {
  color: #ff5151;
  background-color: transparent;
}

/*---------- main menu style end ---------*/
/*------ sticky menu style start ------*/
.sticky.is-sticky {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  -webkit-animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
  animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
}

.sticky.is-sticky .mobile-header-top {
  display: none;
}

.sticky.is-sticky.mobile-header {
  background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .sticky.is-sticky .category-toggle-wrap {
    display: none;
  }
}

/*------ sticky menu style end ------*/
/*----- mobile menu start -----*/
.mobile-header .category-toggle {
  padding: 8px 15px;
}

.mobile-header-top {
  border-bottom: 1px solid #ebebeb;
}

.mobile-header-top .header-top-settings {
  float: none;
}

.mobile-logo {
  max-width: 140px;
  width: 100%;
}

.mobile-main-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .mobile-main-header {
    border-bottom: 1px solid #ebebeb;
  }
}

.mobile-main-header .mobile-menu-toggler {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  position: relative;
  margin-left: 30px;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a {
  color: #252525;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a:hover {
  color: #ff5151;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
  font-size: 13px;
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  right: -8px;
  top: 0;
  position: absolute;
  background-color: #ff5151;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
  margin-left: 15px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn .off-canvas-btn {
  font-size: 30px;
  cursor: pointer;
  padding: 10px 15px;
  line-height: 1;
  padding-right: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn .off-canvas-btn:hover {
  color: #ff5151;
}

.mobile-navigation {
  overflow: hidden;
  max-height: 250px;
}

.mobile-navigation nav {
  height: 100%;
}

.mobile-menu {
  margin-top: 30px;
  height: 100%;
  overflow: auto;
  padding-right: 30px;
  margin-right: -30px;
}

@media only screen and (max-width: 479.98px) {
  .mobile-menu {
    margin-top: 15px;
  }
}

.mobile-menu li > a {
  font-size: 15px;
  color: #252525;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
}

.mobile-menu li > a:hover {
  color: #ff5151;
}

.mobile-menu li ul li {
  border: none;
}

.mobile-menu li ul li a {
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px 15px 8px;
}

.mobile-menu .has-children,
.mobile-menu .menu-item-has-children {
  position: relative;
}

.mobile-menu .has-children .menu-expand,
.mobile-menu .menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-menu .has-children .menu-expand i,
.mobile-menu .menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #252525;
  -webkit-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

.mobile-menu .has-children .menu-expand i:before,
.mobile-menu .menu-item-has-children .menu-expand i:before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-bottom: 1px solid #252525;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.mobile-menu .has-children.active > .menu-expand i:before,
.mobile-menu .menu-item-has-children.active > .menu-expand i:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

/*------ offcanvas widget area start ------*/
.offcanvas-widget-area {
  margin-top: auto;
  padding-bottom: 0px;
}

@media only screen and (max-width: 479.98px) {
  .offcanvas-widget-area {
    padding-bottom: 0;
  }
}

.off-canvas-contact-widget li {
  color: #555;
  font-size: 15px;
  margin-bottom: 5px;
}

.off-canvas-contact-widget li i {
  width: 20px;
}

.off-canvas-contact-widget li a {
  color: #555;
}

.off-canvas-contact-widget li a:hover {
  color: #ff5151;
}

.off-canvas-social-widget {
  margin-top: 20px;
}

.off-canvas-social-widget a {
  color: #555;
  font-size: 18px;
  display: inline-block;
  margin-right: 15px;
}

.off-canvas-social-widget a:hover {
  color: #ff5151;
}

/*------ offcanvas widget area end ------*/
.off-canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 9999;
}

.off-canvas-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.off-canvas-wrapper.open .off-canvas-inner-content {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.off-canvas-wrapper .off-canvas-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  cursor: url("../images/icon/cancel.png"), auto;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.off-canvas-wrapper .off-canvas-inner-content {
  background-color: #fff;
  width: 300px;
  padding: 30px;
  height: 100%;
  position: relative;
  -webkit-transform: translateX(calc(-100% - 50px));
  -ms-transform: translateX(calc(-100% - 50px));
  transform: translateX(calc(-100% - 50px));
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
  .off-canvas-wrapper .off-canvas-inner-content {
    width: 260px;
    padding: 15px;
  }
}

.off-canvas-wrapper .btn-close-off-canvas {
  top: 0;
  left: 100%;
  width: 50px;
  height: 50px;
  font-size: 30px;
  background-color: #ff5151;
  color: #fff;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
  .off-canvas-wrapper .btn-close-off-canvas {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
  }
}

.off-canvas-wrapper .btn-close-off-canvas i {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  display: block;
}

.off-canvas-wrapper .btn-close-off-canvas:hover i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.off-canvas-wrapper .off-canvas-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
  height: 100%;
}

/*------- mobile top bar settings end -------*/
.category-dropdown-wrapper {
  height: 100%;
  -webkit-box-shadow: -1px 0px 3px 0px #cccccc;
  box-shadow: -1px 0px 3px 0px #cccccc;
}

@media only screen and (max-width: 767.98px) {
  .category-dropdown-wrapper {
    display: none;
  }
}

.category-toggle-wrap {
  position: relative;
}

.category-toggle-wrap .category-toggle {
  width: 100%;
  border: none;
  cursor: pointer;
  color: #252525;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 18px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap .category-toggle {
    padding: 10px 0;
  }
}

.category-toggle-wrap .category-toggle i {
  font-size: 22px;
  line-height: 1;
  padding-right: 15px;
}

.category-toggle-wrap .category-toggle span {
  line-height: 1;
  margin-left: auto;
}

.category-toggle-wrap .category-toggle span i {
  font-size: 14px;
  padding-right: 0;
}

.category-toggle-wrap .category-toggle:hover {
  color: #ff5151;
}

.category-toggle-wrap .category-toggle-style_3 {
  background: #fff;
  padding: 18px 20px;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap .category-toggle-style_3 {
    padding: 10px 20px;
  }
}

.category-toggle-wrap nav.category-menu {
  border-top: none;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 269px;
  position: absolute;
  z-index: 11;
  display: none;
  background-color: #fff;
}

.category-toggle-wrap nav.category-menu-style_2 {
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .category-toggle-wrap nav.category-menu-style_2 {
    display: none;
  }
}

.category-toggle-wrap nav.category-menu-style_3 {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .category-toggle-wrap nav.category-menu-style_3 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu {
    max-width: 100%;
    -webkit-box-shadow: 0 2px 5px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 5px 0.35px rgba(0, 0, 0, 0.15);
  }
}

.category-toggle-wrap nav.category-menu > ul.categories-list {
  padding: 17px 20px;
}

.category-toggle-wrap nav.category-menu > ul.categories-list-style_3 {
  padding: 8px 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul {
    max-height: 300px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 575.98px) {
  .category-toggle-wrap nav.category-menu > ul {
    max-height: 220px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li {
    position: relative;
  }
}

.category-toggle-wrap nav.category-menu > ul > li.menu-item-has-children {
  position: relative;
}

.category-toggle-wrap nav.category-menu > ul > li.menu-item-has-children > a::after {
  content: "\f105";
  position: absolute;
  right: 0;
  top: 50%;
  line-height: 1;
  font-family: "FontAwesome";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li.menu-item-has-children > a::after {
    display: none;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .menu-expand {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  height: 50px;
  width: 40px;
  line-height: 50px;
  z-index: 9;
  text-align: center;
}

.category-toggle-wrap nav.category-menu > ul > li > a {
  display: block;
  padding: 10px 0;
  line-height: 24px;
  font-size: 14px;
  color: #555;
  position: relative;
  text-transform: capitalize;
  border-bottom: 1px solid #ebebeb;
}

.category-toggle-wrap nav.category-menu > ul > li > a:hover {
  color: #ff5151;
}

.category-toggle-wrap nav.category-menu > ul > li:first-child a {
  padding-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li:first-child a {
    padding-top: 10px;
  }
}

.category-toggle-wrap nav.category-menu > ul > li:last-child a {
  padding-bottom: 0;
  border-bottom: none;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li:last-child a {
    padding-bottom: 10px;
  }
}

.category-toggle-wrap nav.category-menu > ul > li:hover .category-mega-menu {
  top: 0;
  z-index: 9;
  opacity: 1;
  visibility: visible;
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu {
  position: absolute;
  left: calc(100% + 20px);
  top: 110%;
  width: 800px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px;
  opacity: 0;
  z-index: -9;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu {
    width: 600px;
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu {
    position: static;
    z-index: 9;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
    padding: 0;
    width: 100%;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    border: none;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu.two-column {
  width: 400px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu.two-column {
    width: 100%;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu.two-column > li {
  width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu.two-column > li {
    width: 100%;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu.three-column {
  width: 600px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu.three-column {
    width: 100%;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu.three-column > li {
  width: 33.33%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu.three-column > li {
    width: 100%;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li {
  float: left;
  width: 25%;
  padding: 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li {
    width: 50%;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    padding: 0;
    position: relative;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > a {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #252525;
  text-transform: uppercase;
  margin-bottom: 10px;
  position: relative;
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > a:before,
.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > a:after {
  left: 0;
  bottom: -3px;
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #ebebeb;
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > a:after {
  width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > a {
    padding: 10px 25px 10px 35px;
    line-height: 30px;
    font-size: 14px;
    color: #606060;
    font-weight: 400;
    position: relative;
    margin: 0;
    text-transform: inherit;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li:nth-child(odd) a:after {
  background-color: #f00;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li:nth-child(odd) a:after {
    display: none;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li:nth-child(even) a:after {
  background-color: #ff5151;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li:nth-child(even) a:after {
    display: none;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > ul li:last-child {
  border: none;
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > ul li:last-child {
  margin-bottom: 0;
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > ul li a {
  display: block;
  font-size: 14px;
  color: #606060;
  line-height: 30px;
  padding-bottom: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > ul li a {
    padding: 10px 25px 10px 45px;
    line-height: 30px;
    font-size: 14px;
    color: #606060;
    font-weight: 400;
    position: relative;
  }
}

@media only screen and (max-width: 767.98px) {
  .category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > ul li a {
    padding: 10px 25px 10px 45px;
    line-height: 30px;
    font-size: 14px;
    color: #606060;
    font-weight: 400;
    position: relative;
  }
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > ul li a:hover {
  color: #ff5151;
}

.category-toggle-wrap nav.category-menu > ul > li .category-mega-menu > li > ul li:last-child a {
  border-bottom: 0;
}

/*--
    03. Hero Css
--------------------------------*/
.hero-slider-one .single-slide {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 820px;
  position: relative;
  z-index: 1;
}

.hero-slider-one .single-slide::before {
  background: rgba(0, 0, 0, 0.15);
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .hero-slider-one .single-slide {
    height: 680px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-one .single-slide {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-one .single-slide {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-one .single-slide {
    height: 480px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-slider-one .single-slide {
    height: 420px;
  }
}

.hero-slider-one .slick-arrow {
  background-color: rgba(0, 0, 0, 0.2);
  border: medium none;
  border-radius: 100px;
  color: #ffffff;
  height: 60px;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 999;
  font-size: 20px;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.hero-slider-one .slick-arrow:hover {
  background-color: #ff5151;
}

@media only screen and (max-width: 479px), only screen and (max-width: 767px) {
  .hero-slider-one .slick-arrow {
    height: 40px;
    width: 40px;
  }
}

.hero-slider-one .slick-arrow.slick-next {
  right: 15px;
}

.hero-slider-one .slick-arrow.slick-prev {
  left: 15px;
}

.hero-slider-one:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slick-current {
  /*-- Hero Content One --*/
}

.slick-current .hero-content-one h2 {
  animation-name: fadeInBottom;
  animation-duration: 0.3s;
}

.slick-current .hero-content-one h1 {
  animation-name: fadeInBottom;
  animation-duration: 0.5s;
}

.slick-current .hero-content-one p {
  animation-name: fadeInBottom;
  animation-duration: 0.7s;
}

.slick-current .hero-content-one .btn {
  animation-name: fadeInBottom;
  animation-duration: 0.8s;
}

.slick-current .hero-content-one .slider-inner-image {
  opacity: 1;
  -ms-filter: 1;
  animation-name: fadeInRight;
  animation-duration: 0.9s;
}

.hero-slider-two .single-slide-two {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 950px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .hero-slider-two .single-slide-two {
    height: 680px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-two .single-slide-two {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-two .single-slide-two {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-two .single-slide-two {
    height: 480px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-slider-two .single-slide-two {
    height: 450px;
  }
}

.hero-slider-two .slick-arrow {
  background-color: rgba(0, 0, 0, 0.2);
  border: medium none;
  border-radius: 100px;
  color: #ffffff;
  height: 60px;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 999;
  font-size: 20px;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.hero-slider-two .slick-arrow:hover {
  background-color: #ff5151;
}

@media only screen and (max-width: 479px), only screen and (max-width: 767px) {
  .hero-slider-two .slick-arrow {
    height: 40px;
    width: 40px;
  }
}

.hero-slider-two .slick-arrow.slick-next {
  right: 15px;
}

.hero-slider-two .slick-arrow.slick-prev {
  left: 15px;
}

.hero-slider-two:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hero-slider-one .slider-text-info, .hero-slider-two .slider-text-info {
  z-index: 1;
}

.hero-slider-one .slider-text-info h2, .hero-slider-two .slider-text-info h2 {
  font-weight: 600;
}

.hero-slider-one .slider-text-info h2 span, .hero-slider-two .slider-text-info h2 span {
  color: #ff5151;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-one .slider-text-info h2, .hero-slider-two .slider-text-info h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-one .slider-text-info h2, .hero-slider-two .slider-text-info h2 {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-one .slider-text-info h2, .hero-slider-two .slider-text-info h2 {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-slider-one .slider-text-info h2, .hero-slider-two .slider-text-info h2 {
    font-size: 20px;
    margin-bottom: 0px;
    line-height: 30px;
  }
}

.hero-slider-one .slider-text-info h1, .hero-slider-two .slider-text-info h1 {
  text-transform: capitalize;
  margin-bottom: 20px;
}

.hero-slider-one .slider-text-info h1 span, .hero-slider-two .slider-text-info h1 span {
  color: #ff5151;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-one .slider-text-info h1, .hero-slider-two .slider-text-info h1 {
    font-size: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-one .slider-text-info h1, .hero-slider-two .slider-text-info h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-one .slider-text-info h1, .hero-slider-two .slider-text-info h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-slider-one .slider-text-info h1, .hero-slider-two .slider-text-info h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

.hero-slider-one .slider-text-info p, .hero-slider-two .slider-text-info p {
  line-height: 24px;
  margin: 0;
  max-width: 500px;
}

.hero-slider-one .slider-text-info .slider-btn, .hero-slider-two .slider-text-info .slider-btn {
  font-weight: 600;
  margin-top: 60px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
}

.hero-slider-one .slider-text-info .slider-btn::before, .hero-slider-two .slider-text-info .slider-btn::before {
  position: absolute;
  content: "";
  left: 0;
  top: -40px;
  height: 30px;
  width: 1px;
  background: #ff5151;
}

.hero-slider-one .slider-inner-image, .hero-slider-two .slider-inner-image {
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  opacity: 0;
  -ms-filter: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-one .slider-inner-image, .hero-slider-two .slider-inner-image {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-one .slider-inner-image, .hero-slider-two .slider-inner-image {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .hero-slider-one .slider-inner-image, .hero-slider-two .slider-inner-image {
    display: none;
  }
}

.hero-slider-two .slider-text-info {
  margin-top: 80px;
}

.hero-slider-two .slider-btn-two {
  font-weight: 600;
  margin-top: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
}

.hero-slider-two .slider-btn-two::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -40px;
  height: 30px;
  width: 1px;
  background: #ff5151;
}

.hero-slider-two .social-top {
  margin-top: 40px;
}

.slick-current {
  /*-- Hero Content One --*/
}

.slick-current .hero-content-one h3 {
  animation-name: fadeInBottom;
  animation-duration: 0.3s;
}

.slick-current .hero-content-one h1 {
  animation-name: fadeInBottom;
  animation-duration: 0.5s;
}

.slick-current .hero-content-one p {
  animation-name: fadeInBottom;
  animation-duration: 0.7s;
}

.slick-current .hero-content-one .btn {
  animation-name: fadeInBottom;
  animation-duration: 0.8s;
}

.slick-current .hero-content-one .slider-inner-image {
  opacity: 1;
  -ms-filter: 1;
  animation-name: fadeInRight;
  animation-duration: 0.9s;
}

.hero-slider-one .slick-dots {
  text-align: center;
  margin-top: 20px;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
}

.hero-slider-one .slick-dots li {
  display: inline-block;
  margin: 0 4px;
}

.hero-slider-one .slick-dots li button {
  background-image: url(../images/icon/slider-btn-01.png);
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  border-radius: 50%;
}

.hero-slider-one .slick-dots li.slick-active button {
  background-image: url(../images/icon/slider-btn-active.png);
}

/*--
    04. Search Css
--------------------*/
.search-area {
  background: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 30px 0 45px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.search-area h2 {
  color: #000000;
  font-weight: 600;
}

.search-area input {
  width: 100%;
}

.search-area .btn {
  background: #ff5151;
  border: 1px solid #ff5151;
  color: #ffffff;
}

.search-area .btn i {
  font-size: 12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .search-area {
    padding: 20px 0 35px 0;
  }
  .search-area h2 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-area {
    padding: 10px 0 35px 0;
  }
  .search-area h2 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search-area {
    padding: 10px 0 35px 0;
  }
  .search-area h2 {
    font-size: 28px;
    margin-bottom: 0;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .search-area {
    position: inherit;
    padding: 10px 0 35px 0;
  }
  .search-area h2 {
    font-size: 28px;
    margin-bottom: 0;
    line-height: 28px;
  }
  .search-area .btn {
    padding: 9px 31px;
  }
}

.class-grow .col {
  flex-grow: 1;
}

.nice-select, .input-box-2 {
  width: 100%;
  height: 45px;
}

.btn-custom {
  max-width: 180px;
}

@media only screen and (max-width: 767px) {
  .btn-custom {
    max-width: 150px;
  }
}

.search-area input {
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.nice-select .list {
  min-width: 100%;
  background-color: #f1f1ff;
  border: 1px solid #f1f1ff;
  box-shadow: none;
}

.container-100 {
  max-width: 1470px;
  margin: auto;
}

/*--
   05. Product Area Css
------------------------------*/
.single-product-wrap {
  margin-top: 30px;
}

.single-product-wrap:hover .product-action {
  opacity: 1;
  -ms-filter: 1;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-product-wrap:hover .product-image > a {
  border-bottom: 1px solid #ff5151;
}

.single-product-wrap:hover a:before {
  opacity: 1;
  -ms-filter: 1;
}

.product-image {
  position: relative;
}

.product-image > a {
  display: block;
  position: relative;
  border-bottom: 1px solid #eeeeee;
}

.product-image > a img {
  width: 100%;
}

.product-image .product-action {
  display: inline-block;
  left: 0;
  position: absolute;
  text-align: center;
  top: 90%;
  width: 100%;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.product-image .product-action .wishlist,
.product-image .product-action .add-to-cart,
.product-image .product-action .quick-view {
  background: #ddd none repeat scroll 0 0;
  display: inline-block;
  font-size: 18px;
  height: 40px;
  line-height: 44px;
  width: 40px;
  color: #6f6f6f;
}

.product-image .product-action .wishlist:hover,
.product-image .product-action .add-to-cart:hover,
.product-image .product-action .quick-view:hover {
  background: #ff5151;
  color: #ffffff;
}

.product-image span.label {
  position: absolute;
  top: 0;
  background: #ff5151;
  color: #ffffff;
  font-size: 12px;
  padding: 2px 15px;
  border-radius: 25px;
}

.product-content {
  margin-top: 20px;
  text-align: center;
}

.product-content h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.product-content .price-box {
  font-size: 14px;
}

.product-content .price-box span {
  margin: 0 8px;
}

.product-content .price-box .new-price {
  color: #ff5151;
}

.product-content .price-box .old-price {
  text-decoration: line-through;
}

.product-active-lg-4 {
  overflow: hidden;
}

.product-active-lg-4 .slick-arrow, .product-two-row-4 .slick-arrow {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 15px;
  right: auto;
  background: #ddd;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
}

.product-active-lg-4 .slick-arrow.slick-next, .product-two-row-4 .slick-arrow.slick-next {
  right: 15px;
  left: auto;
}

.product-active-lg-4:hover .slick-arrow, .product-two-row-4:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}

.product-active-lg-4:hover .slick-arrow.slick-prev, .product-two-row-4:hover .slick-arrow.slick-prev {
  left: 0px;
}

.product-active-lg-4:hover .slick-arrow.slick-next, .product-two-row-4:hover .slick-arrow.slick-next {
  right: 0px;
}

/*--
    06. Testimonial
--------------------*/
.testimonial-bg {
  background: url(../images/bg/testimonial-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.content-testimonial {
  padding: 40px 35px 40px 30px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  text-align: center;
}

.content-testimonial::after {
  position: absolute;
  content: '';
  background: url(../image/icon/testimonial-01.png);
  height: 175px;
  width: 33px;
  top: 30px;
  left: 0;
  background-repeat: no-repeat;
}

.testimonial-info {
  padding: 30px 30px;
  position: relative;
}

.testimonial-info::after {
  position: absolute;
  content: '';
  background: url(../image/icon/testimonial-02.png);
  background-repeat: repeat;
  height: 120px;
  width: 131px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
}

.testimonial-info h5 {
  font-weight: 500;
}

.testimonial-inner {
  padding-bottom: 50px;
}

.testimonial-inner .test-author {
  margin-top: 20px;
}

.test-cont {
  text-align: center;
  margin: auto;
}

.test-cont img {
  display: inline-block;
}

.test-cont p {
  margin-top: 30px;
}

.testimonial-slider .slick-dots {
  text-align: center;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 30px auto 0 0;
}

.testimonial-slider .slick-dots li {
  display: inline-block;
  margin: 0 4px;
}

.testimonial-slider .slick-dots li button {
  background-image: url(../images/icon/slider-btn-01.png);
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  border-radius: 50%;
}

.testimonial-slider .slick-dots li.slick-active button {
  background-image: url(../images/icon/slider-btn-active.png);
}

/*--
   07. Product Details Css
---------------------------*/
.modal-dialog {
  max-width: 1024px;
}

.modal-wrapper .modal-body {
  padding: 30px 15px;
}

.modal-wrapper button.close {
  font-size: 24px;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 5px;
}

.modal-wrapper .modal {
  display: inline-block !important;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: -9;
}

.modal-wrapper .modal.show {
  visibility: visible;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 99950;
}

.modal-wrapper .modal-dialog {
  max-width: 1000px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-wrapper .modal-dialog {
    width: 680px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-wrapper .modal-dialog {
    width: 470px;
  }
}

@media only screen and (max-width: 479px) {
  .modal-wrapper .modal-dialog {
    width: 300px;
  }
}

.portfolio-full-image {
  border: 1px solid #ddd;
}

.portfolio-full-image img {
  width: 100%;
}

.pot-small-img {
  border: 1px solid #ddd;
}

.pot-small-img img {
  width: 100%;
}

.product_big_images-right {
  float: right;
  width: calc(100% - 115px);
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_big_images-right {
    width: calc(100% - 0px);
    padding-left: 0px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product_big_images-right {
    width: calc(100% - 0px);
    padding-left: 0px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .product_big_images-right {
    width: calc(100% - 0px);
    padding-left: 0px;
    margin-bottom: 10px;
  }
}

.product_small_images-left {
  float: left;
  width: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_small_images-left {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product_small_images-left {
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .product_small_images-left {
    width: 100%;
  }
}

.vartical-product-active li {
  margin: 0 0px 10px 0;
  border: 1px solid #ddd !important;
}

.vartical-product-active li:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vartical-product-active li {
    margin: 0 10px 0px 0;
  }
}

@media only screen and (max-width: 767px) {
  .vartical-product-active li {
    margin: 0 10px 0px 0;
  }
}

@media only screen and (max-width: 479px) {
  .vartical-product-active li {
    margin: 0 10px 0px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vartical-product-active .slick-list {
    margin: 0 -9px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .vartical-product-active .slick-list {
    margin: 0 -9px 0 0;
  }
}

@media only screen and (max-width: 479px) {
  .vartical-product-active .slick-list {
    margin: 0 -9px 0 0;
  }
}

.product_small_images-left .arrow-prv,
.product_small_images-left .arrow-next,
.product_small_images-bottom .arrow-prv,
.product_small_images-bottom .arrow-next {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #ddd;
  color: #fff;
  z-index: 9;
  position: absolute;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.product_small_images-left .arrow-prv:hover,
.product_small_images-left .arrow-next:hover,
.product_small_images-bottom .arrow-prv:hover,
.product_small_images-bottom .arrow-next:hover {
  background: #ff5151;
}

.product_small_images-left .arrow-next,
.product_small_images-bottom .arrow-next {
  right: 0;
}

.product_small_images-left:hover .arrow-prv,
.product_small_images-left:hover .arrow-next,
.product_small_images-bottom:hover .arrow-prv,
.product_small_images-bottom:hover .arrow-next {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 479px), only screen and (max-width: 767px) {
  .product_details_info {
    margin-top: 20px;
  }
}

.product_big_images-top {
  margin-bottom: 15px;
}

.horizantal-product-active .pot-small-img {
  margin-right: 15px;
}

.product_small_images-bottom .slick-list {
  margin-right: -15px;
}

.product_details_info > h2 {
  color: #444444;
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
}

.product-rating li {
  padding-right: 10px;
  color: #ff5151;
  font-size: 18px;
}

.pro_details p {
  margin-top: 10px;
}

.pro_dtl_prize li {
  font-size: 22px;
  color: #ff5151;
  display: inline-block;
  margin-right: 5px;
  margin-top: 10px;
  font-weight: 500;
}

.pro_dtl_prize li.old_prize {
  font-size: 20px;
  color: #000000;
  text-decoration: line-through;
}

.title_2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}

.pro_dtl_color {
  margin-top: 15px;
}

.pro_dtl_color li {
  font-size: 18px;
  display: inline-block;
  padding-right: 10px;
}

.pro_dtl_color li:last-child {
  padding: 0;
}

.pro_dtl_color .red a {
  color: #ff5d42;
}

.pro_dtl_color .blue a {
  color: #425dff;
}

.pro_dtl_color .perpal a {
  color: #fd42ff;
}

.pro_dtl_color .yellow a {
  color: #ffd542;
}

.pro_dtl_size {
  margin-top: 15px;
}

.pro_dtl_size .pro_choose_size li {
  display: inline-block;
  padding-right: 10px;
}

.pro_dtl_size .pro_choose_size li:last-child {
  padding: 0;
}

.product-quantity-action {
  margin-top: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.product-quantity-action .product-quantity {
  margin-left: 10px;
}

.product-quantity-action .product-quantity input {
  width: 40px;
  text-align: center;
  height: 30px;
}

.pro_social_share {
  margin-top: 15px;
}

.pro_social_share .pro_social_link > li {
  display: inline-block;
  padding-left: 10px;
}

.pro_dtl_btn {
  margin-top: 20px;
  display: flex;
}

.pro_dtl_btn li {
  margin-right: 10px;
}

.pro_dtl_btn li a {
  border: 1px solid #dddddd;
  display: block;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}

.pro_dtl_btn li a.buy_now_btn {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  width: 120px;
}

.pro_dtl_btn li a:hover {
  background: #ff5151;
  color: #ffffff;
  border: 1px solid #ff5151;
}

.product-details-tab .nav {
  justify-content: center;
  border-bottom: 1px solid #ddd;
}

.product-details-tab .nav li {
  margin-right: 15px;
}

.product-details-tab .nav li a {
  font-weight: 500;
  font-size: 18px;
  display: block;
  padding-bottom: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
}

.product-details-tab .nav li a::before {
  content: "";
  background: #ff5151;
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.product-details-tab .nav li a.active {
  color: #ff5151;
}

.product-details-tab .nav li a.active::before {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  width: 100%;
}

@media only screen and (max-width: 479px) {
  .product-details-tab .nav li a {
    padding-bottom: 10px;
  }
  .product-details-tab .nav li a:last-child {
    margin-top: 10px;
  }
}

.product-details-tab .nav li:hover > a::before {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  width: 100%;
}

.single-product-gallery {
  float: left;
  padding: 5px;
  width: 50%;
}

.title_3 {
  font-size: 22px;
  font-weight: 500;
}

.feature_list li {
  margin-bottom: 5px;
}

.feature_list li:last-child {
  margin: 0;
}

.feature_list li a i {
  font-size: 18px;
  color: #000000;
  margin-right: 8px;
}

.pro_review {
  display: flex;
}

.pro_review.ans {
  margin-bottom: 50px;
  margin-left: 50px;
  margin-top: 50px;
}

@media only screen and (max-width: 479px) {
  .pro_review.ans {
    margin-bottom: 30px;
    margin-left: 20px;
    margin-top: 30px;
  }
}

/*--
    08. Banner CSs
--------------------------*/
.single-banner {
  position: relative;
}

.single-banner:hover .banner-content {
  visibility: visible;
  opacity: 1;
  transform: rotate(0deg);
}

.banner-content {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 50px;
  background: rgba(255, 255, 255, 0.6);
  height: auto;
  width: auto;
  left: 0;
  bottom: 0;
  margin: 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  transform: rotate(15deg);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner-content {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content {
    padding: 0 10px;
    margin: 10px;
  }
  .banner-content p {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content {
    padding: 0 10px;
    margin: 10px;
  }
  .banner-content p {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-content {
    padding: 0 10px;
    margin: 10px;
  }
  .banner-content p {
    margin-bottom: 5px;
  }
}

.banner-content a {
  color: #ff5151;
  font-weight: 600;
}

.single-banner-two:hover img {
  transform: scale(1.03);
}

.single-banner-two:hover .banner-content-two h3 {
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInUp;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInUp;
}

.single-banner-two:hover .banner-content-two a {
  -webkit-animation: 600ms ease-in-out 0s normal none 1 running fadeInUp;
  animation: 600ms ease-in-out 0s normal none 1 running fadeInUp;
}

.single-banner-two {
  overflow: hidden;
}

.single-banner-two img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.banner_bg-two {
  background: url(../images/banner/banner.jpg);
}

.banner-content-two {
  top: 0;
  right: 0;
  padding: 60px 50px;
  height: auto;
  width: auto;
  left: 0;
  bottom: 0;
  margin-left: 50px;
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;
}

.banner-content-two h3 span {
  color: #ff5151;
  font-size: 46px;
}

.banner-content-two a {
  margin-top: 30px;
  color: #ff5151;
  font-weight: 600;
  font-size: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-two {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-two {
    padding: 40px 10px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-two {
    padding: 30px 10px;
    margin-left: 10px;
  }
  .banner-content-two h3 {
    font-size: 18px;
    line-height: 20px;
  }
  .banner-content-two h3 span {
    color: #ff5151;
    font-size: 22px;
  }
  .banner-content-two a {
    margin-top: 20px;
  }
}

/*--
    09. Bloag Css
-----------------------------*/
.single-blog .blog-image {
  position: relative;
}

.single-blog .meta-tag {
  width: 80px;
  background: #ff5151;
  height: 60px;
  line-height: 60px;
  text-align: center;
  position: absolute;
  color: #fff;
  top: 0;
}

.single-blog .meta-tag span {
  font-size: 20px;
  font-weight: 600;
}

.single-blog .blog-content {
  margin-top: 25px;
  max-width: 480px;
}

.single-blog .blog-content .read-more {
  margin-top: 15px;
}

.single-blog .blog-content .read-more a {
  font-weight: 600;
}

.our-blog-contnet {
  padding: 30px;
  border-bottom: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-left: 1px solid #ececec;
}

.our-blog-contnet h5 {
  font-weight: 600;
  margin-bottom: 5px;
}

.our-blog-contnet .post_meta {
  margin-bottom: 10px;
  font-size: 12px;
}

.our-blog-contnet .post_meta ul li {
  display: inline-block;
  position: relative;
  padding: 0 10px 0 0;
  margin: 0 10px 0 0;
  position: relative;
}

.our-blog-contnet .post_meta ul li::before {
  content: "";
  width: 1px;
  height: 10px;
  background: #7c7c7c;
  top: 50%;
  right: 0;
  position: absolute;
  transform: translateY(-50%);
}

.our-blog-contnet .post_meta ul li:last-child::before {
  display: none;
}

.our-blog-contnet .post_meta ul li a {
  color: #ff5151;
}

.our-blog-contnet > p {
  font-size: 14px;
}

.our-blog-contnet .button-block {
  margin-top: 20px;
}

.our-blog-contnet .button-block .botton-border {
  padding: 5px 30px;
  border: 2px solid #ff5151;
  color: #ff5151;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
}

.our-blog-contnet .button-block .botton-border:hover {
  background: #ff5151;
  color: #ffffff;
}

.single-our-blog:hover .our-blog-image a {
  position: relative;
}

.single-our-blog:hover .our-blog-image a::before {
  opacity: 1;
  visibility: visible;
}

.single-our-blog:hover .our-blog-image .in-our-blog-icon {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.our-blog-image {
  position: relative;
}

.our-blog-image a {
  position: relative;
  display: block;
}

.our-blog-image a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.58);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.our-blog-image a img {
  width: 100%;
}

.our-blog-image .in-our-blog-icon {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 45px;
  text-align: center;
  background: #fff;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 50px;
  margin-top: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.blog-top-bar {
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
  padding: 10px 20px;
  color: #ff5151;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-top-bar {
    padding: 6px;
  }
}

.blog-top-bar .blog-item-filter-list li a {
  font-size: 20px;
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
}

.blog-top-bar .blog-item-filter-list li a::before {
  background: #ff5151;
  content: "";
  position: absolute;
  height: 15px;
  width: 1px;
  left: 0;
  top: 6px;
}

.blog-top-bar .blog-item-filter-list li:first-child a::before {
  display: none;
}

.single-blog-list-view .our-blog-contnet {
  padding: 0;
  border: none;
}

@media only screen and (max-width: 767px) {
  .single-blog-list-view .our-blog-contnet {
    margin-top: 30px;
  }
}

.blog-details-area .our-blog-contnet {
  padding: 30px 0;
  border: none;
  border-bottom: 1px solid #ddd;
}

.blockquote-blog {
  background: #ff5151;
  padding: 20px 70px;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}

.blockquote-blog::after {
  position: absolute;
  width: 40px;
  height: 40px;
  background: url(../images/icon/small-quote-icon.png) no-repeat;
  content: "";
  left: 35px;
  top: 25px;
  z-index: 1;
  right: auto;
}

.blockquote-blog::before {
  position: absolute;
  width: 80px;
  height: 80px;
  background: url(../images/icon/quote-icon01.png) no-repeat;
  content: "";
  right: 0;
  bottom: 10px;
  z-index: 1;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .blockquote-blog {
    padding: 30px 30px;
  }
  .blockquote-blog::after {
    left: 15px;
    top: 15px;
  }
}

.blockquote-blog p {
  margin: 0;
}

.blockquote-blog h5 {
  margin: 0;
  float: right;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  padding-left: 45px;
}

.blockquote-blog h5::after {
  position: absolute;
  width: 35px;
  height: 1px;
  background: #ddd;
  content: "";
  left: 0;
  top: 50%;
}

.admin-author-details {
  margin-top: 60px;
  border-top: 1px solid #ddd;
  padding-top: 60px;
}

.admin-aouthor {
  display: flex;
  align-items: center;
  background: #f9f8f8;
  padding: 30px;
}

.admin-aouthor .admin-image {
  width: 40%;
}

.admin-info .name h5 {
  font-weight: 700;
}

.admin-info .name p {
  color: #ff5151;
  margin-bottom: 10px;
}

.admin-info .author-socialicons {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.admin-info .author-socialicons li {
  list-style: none;
  display: inline-block;
}

.admin-info .author-socialicons li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #ff5151;
  color: #ffffff;
  text-align: center;
  border-radius: 100%;
  margin: 2.5px;
  font-size: 16px;
}

.admin-info .author-socialicons li a:hover {
  background: #333;
}

.review_address_inner {
  position: relative;
}

.review_address_inner > h5 {
  font-weight: 600;
  margin-bottom: 30px;
}

.pro_review {
  display: flex;
  margin-bottom: 50px;
  position: relative;
}

.pro_review:nth-child(3n) {
  padding-left: 120px;
}

@media only screen and (max-width: 767px) {
  .pro_review:nth-child(3n) {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .pro_review {
    margin-bottom: 20px;
  }
}

.review_thumb {
  margin-right: 20px;
  min-width: 20%;
}

@media only screen and (max-width: 767px) {
  .review_thumb {
    min-width: 25%;
  }
}

.review_info h5 {
  font-weight: 600;
}

.rating_send {
  position: absolute;
  top: 0;
  right: 0;
}

.rating_send a {
  display: block;
  float: right;
  font-size: 16px;
  margin-top: 0px;
}

.comments-reply-area .comment-reply-title {
  font-weight: 600;
}

.comments-reply-area .comment-input input {
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 6px 20px;
}

.comments-reply-area .comment-input textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #ddd;
  padding: 20px;
}

.comments-reply-area .comment-form-submit {
  margin-top: 30px;
}

.comments-reply-area .comment-form-submit .comment-submit {
  background: #ff5151;
  color: #ffffff;
  font-weight: 600;
  border: none;
  text-transform: uppercase;
  padding: 8px 30px;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 5px;
}

.comments-reply-area .comment-form-submit .comment-submit:hover {
  background: #333;
}

.comments-reply-area {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 60px;
}

/*--
    10. Footer Css
-------------------------*/
.footer-top {
  background: #f6f6f6;
}

.widget-footer .footer-logo {
  margin-bottom: 10px;
  margin-top: -5px;
}

.widget-footer .title-widget {
  margin-bottom: 30px;
}

.newsletter-footer {
  margin-top: 18px;
}

.newsletter-footer input {
  width: 100%;
  max-width: 300px;
  border: none;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  background-color: transparent;
}

.newsletter-footer .subscribe-button {
  width: 100%;
}

.newsletter-footer .subscribe-btn {
  padding: 6px 20px;
  border: none;
  background: #ff5151;
  color: #ffffff;
  margin-top: 12px;
}

.footer-list li {
  margin-bottom: 8px;
}

.footer-list li:last-child {
  margin-bottom: 0;
}

.footer-contact li {
  margin-bottom: 10px;
}

.footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-contact label {
  display: block;
  font-weight: 500;
  margin-bottom: 2px;
}

.footer-blog li {
  margin-bottom: 25px;
}

.footer-blog li:last-child {
  margin-bottom: 0;
}

.widget-blog-wrap {
  position: relative;
}

.widget-blog-image {
  max-width: 76px;
  position: absolute;
  min-height: 70px;
}

.widget-blog-content {
  padding-left: 80px;
}

.widget-blog-content h6 {
  font-weight: 400;
  font-size: 14px;
}

.widget-blog-content span {
  font-size: 13px;
  margin-right: 8px;
}

.footer-bottom {
  background: #f1f1f1;
  padding: 20px 0;
}

/*--
    11. Widget Css
---------------------*/
.widget-title {
  font-weight: 600;
  position: relative;
  padding-left: 10px;
  padding-bottom: 6px;
  margin-bottom: 30px;
  display: inline-block;
  margin-top: -5px;
}

.widget-title::after {
  position: absolute;
  content: '';
  background: #ff5151;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
}

.widget-title::before {
  position: absolute;
  content: '';
  background: #ff5151;
  width: 1px;
  height: 25px;
  left: 0;
  bottom: 0;
}

.left-sidebar {
  padding-right: 20px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .left-sidebar {
    padding-right: 0px;
  }
}

.right-sidebar {
  padding-left: 20px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .right-sidebar {
    padding-left: 0px;
  }
}

.left-sidebar {
  padding-right: 20px;
}

.single-widget {
  margin-top: 60px;
}

.single-widget:first-child {
  margin-top: 30px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-widget {
    margin-top: 35px;
  }
}

.widget-search-form input {
  width: 100%;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid #ff5151;
}

.widget-categories ul li {
  margin-bottom: 10px;
}

.widget-categories ul li:last-child {
  margin-bottom: 0;
}

.widget-categories ul li a {
  display: block;
}

.widget-categories ul li a span {
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .widgets {
    padding-top: 30px;
  }
}

.single-related {
  position: relative;
  padding-left: 130px;
  min-height: 80px;
  margin-bottom: 20px;
}

.single-related:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 479px) {
  .single-related {
    padding-left: 0px;
  }
}

.single-related-image {
  position: absolute;
  left: 0;
  top: 5px;
  overflow: hidden;
  width: 120px;
}

@media only screen and (max-width: 479px) {
  .single-related-image {
    position: inherit;
    width: 100%;
    margin-bottom: 20px;
  }
}

.single-related-contents h4 {
  font-weight: 600;
  margin-bottom: 2px;
}

.single-related-contents .post_meta {
  margin-bottom: 5px;
}

.tweet-cont-wrap ul li {
  margin-bottom: 20px;
}

.tweet-cont-wrap ul li:last-child {
  margin-bottom: 0;
}

.tweet-cont-wrap ul li > a {
  color: #ff5151;
  font-weight: 600;
}

.tweet-cont-wrap ul li span {
  color: #ff5151;
  font-weight: normal;
  margin-top: 8px;
}

.tag {
  margin-bottom: -8px;
  margin-right: -5px;
  margin-left: -5px;
  margin-top: -5px;
}

.tag a {
  color: #ff5151;
  border: 1px solid #ff5151;
  padding: 6px 12px;
  border-radius: 25px;
  margin: 4px;
  margin-bottom: 10px;
}

.tag a:hover {
  color: #ffffff;
  background: #ff5151;
}

.our-widget-agent {
  margin-left: -10px;
  margin-right: -10px;
}

.our-widget-agent .widget-agent {
  width: 43%;
  float: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 0px 10px 20px 10px;
  border-radius: 10px;
}

.our-widget-agent .widget-agent .name {
  background: #f1f1ff;
  padding: 10px;
}

.our-widget-agent .widget-agent .name h5 {
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .our-widget-agent .widget-agent {
    width: 41%;
  }
}

/*-- 
   shop sidebar Css
------------------------------*/
.shop-sidebar h4 {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
  text-transform: uppercase;
}

.shop-sidebar h4::before {
  position: absolute;
  content: "";
  background: #ff5151;
  width: 50px;
  height: 2px;
  left: 0;
  bottom: -1px;
}

.shop-sidebar ul > li {
  display: block;
  margin-bottom: 10px;
}

.shop-sidebar ul > li:last-child {
  margin-bottom: 0px;
}

.shop-sidebar ul > li > a {
  font-size: 15px;
  display: block;
  font-weight: 500;
}

.shop-sidebar ul > li > a span {
  float: right;
}

.sidbar-product .sidbar-product-inner {
  overflow: hidden;
  display: flex;
  margin-top: 20px;
}

.sidbar-product .sidbar-product-inner .product-image {
  padding-right: 10px;
  width: 100px;
}

.sidbar-product .sidbar-product-inner .product-content {
  margin-top: 0;
}

.sidbar-product .sidbar-product-inner .product-content h3 {
  font-size: 15px;
}

.sidbar-product .sidbar-product-inner .product-content .price-box {
  font-size: 16px;
}

.ui-widget-content {
  background: #777 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0px;
  height: 4px;
  margin: 35px 0 25px;
}

.ui-widget-content .ui-state-default {
  background: #ffffff;
  border: 3px solid #ff5151;
  border-radius: 25px;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  font-weight: normal;
  height: 15px;
  width: 15px;
}

.ui-widget-content .ui-state-default:hover {
  background: #ff5151;
}

.ui-widget-content .ui-slider-range {
  background: #ff5151;
}

.filter-price-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}

.filter-price-cont {
  font-size: 14px;
}

.filter-price-cont .input-type input {
  border: medium none;
  padding: 0 8px 0 0;
  text-align: center;
  width: 40px;
}

.filter-price-cont .input-type:last-child {
  padding-left: 8px;
}

.filter-price-cont .add-to-cart-button {
  background: #ff5151;
  display: inline-block;
  font-weight: 500;
  padding: 1px 13px;
  right: 0;
  color: #ffffff;
}

.filter-price-cont .add-to-cart-button:hover {
  background: #000000;
}

.sidebar-tag > a {
  border: 1px solid #000000;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 10px 0  0;
  padding: 4px 15px;
}

.sidebar-tag > a:hover {
  background: #ff5151;
  color: #ffffff;
  border: 1px solid #ff5151;
}

.shop-top-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  align-items: center;
}

.shop-item-filter-list li a {
  font-size: 26px;
  font-weight: 500;
  margin-right: 20px;
}

.shop-item-filter-list li a .active {
  color: #ff5151;
}

.shop-item-filter-list li .active {
  color: #ff5151;
}

.product-short {
  display: flex;
  font-size: 14px;
}

.product-short p {
  margin: 0 15px 0 0;
}

.product-short .nice-select {
  border: 1px solid #ddd;
  padding: 0 8px;
  height: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sidebar-wrap {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sidebar-wrap {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .shop-sidebar-wrap {
    margin-top: 60px;
  }
}

.product-layout-list p {
  margin-top: 20px;
}

.product-layout-list:hover .product-action {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
}

.product-layout-list:hover .product-image a::before {
  opacity: 1;
}

/*--
    12. Compare Page Css
-----------------------------------------*/
.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  padding-top: 70px;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  clear: both;
  color: #454545;
  float: left;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 15px;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-color {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-stock {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background-color: #f6f6f6;
  color: #000000;
  border-radius: 50px;
  display: inline-block;
  width: 170px;
  padding: 10px 6px;
  transition: all 0.7s;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #000000;
  font-size: 14px;
}

/*--
    13. Login And Register Css
----------------------------------*/
.login-register-tab-list {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.login-register-tab-list a {
  position: relative;
}

.login-register-tab-list a::before {
  background-color: #454545;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  top: 3px;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-tab-list a:last-child:before {
  display: none;
}

.login-register-tab-list a.active h4,
.login-register-tab-list a:hover h4 {
  color: #ff5151;
}

.login-register-tab-list a h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 20px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.login-input-box input {
  border: 1px solid #999999;
  color: #666666;
  margin-bottom: 20px;
  padding: 8px 12px;
  width: 100%;
  font-size: 14px;
}

.login-toggle-btn a {
  float: right;
  color: red;
  font-size: 14px;
}

@media only screen and (max-width: 479px) {
  .login-toggle-btn a {
    float: inherit;
  }
}

.login-toggle-btn input {
  margin-right: 5px;
}

.login-form-container {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 60px;
  text-align: left;
}

@media only screen and (max-width: 479px) {
  .login-form-container {
    padding: 30px;
  }
}

.login-form-container .login-btn,
.login-form-container .register-btn {
  margin-top: 20px;
  padding: 8px 30px;
}

/*--
    14. My Account Page Css
--------------------------*/
.dashboard-upper-info [class*="col-"]:not(:last-child) .d-single-info {
  border-right: 1px solid #ebebeb;
  padding: 30px 20px;
}

.dashboard-upper-info [class*="col-"]:not(:last-child) .d-single-info p {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-upper-info [class*="col-"]:not(:last-child) .d-single-info {
    border-right: medium none;
    padding: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-upper-info [class*="col-"]:not(:last-child) .d-single-info {
    border-right: medium none;
    padding: 0 0 20px;
  }
}

.dashboard-upper-info {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  margin-bottom: 60px;
}

.dashboard-upper-info a.view-cart {
  background: #ff5151;
  color: #ffffff;
  font-size: 14px;
  padding: 5px 12px;
  text-transform: capitalize;
}

.dashboard-upper-info a.view-cart:hover {
  background: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-upper-info {
    border-bottom: medium none;
    border-top: medium none;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-upper-info {
    border-bottom: medium none;
    border-top: medium none;
  }
}

.user-name span {
  color: #303030;
  font-size: 16px;
  font-weight: 600;
}

.dashboard-list li a {
  border-bottom: 1px solid #ebebeb;
  color: #222222;
  display: block;
  font-size: 14px;
  padding: 12px 0;
  text-transform: capitalize;
}

.dashboard-list li:first-child {
  border-top: 1px solid #ebebeb;
}

.dashboard-content {
  border: 1px solid #ebebeb;
  padding: 30px;
}

.dashboard-content h3 {
  border-bottom: 1px solid #ebebeb;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 25px;
  padding-bottom: 15px;
  text-transform: capitalize;
}

.dashboard-content .table thead {
  background: #f6f6f6 none repeat scroll 0 0;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-content .table td {
    min-width: 260px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-content .table td {
    min-width: 260px;
  }
}

.dashboard-content .view {
  background: #ff5151;
  color: #ffffff;
  font-size: 14px;
  padding: 5px 12px;
  text-transform: capitalize;
}

.dashboard-content .view:hover {
  background: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-content {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-content {
    margin-top: 60px;
  }
}

.billing-address {
  margin-bottom: 10px;
}

.biller-name {
  margin-bottom: 0;
  margin-top: 10px;
}

.account-login-form .custom-checkbox {
  display: flex;
}

.account-login-form input[type="checkbox"] {
  height: 20px;
  margin: 0 10px 0 0;
  position: relative;
  top: 3px;
  width: auto;
}

.account-login-form .example {
  margin-bottom: 15px;
}

.account-login-form .default-btn {
  margin-top: 15px;
  padding: 6px 28px;
}

.account-input-box label {
  display: block;
  font-size: 15px;
  margin-bottom: 3px;
}

.account-input-box input {
  width: 100%;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  padding: 4px 10px;
  font-size: 15px;
}

/*--    
    15. Error 404 Page Css
---------------------------*/
.search-error-wrapper h1 {
  color: #ff5151;
  font-size: 120px;
  line-height: 86px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .search-error-wrapper h1 {
    font-size: 70px;
    line-height: 50px;
    margin-bottom: 40px;
  }
}

.error-form-input {
  position: relative;
  width: 275px;
  margin: 30px auto;
}

.error-form-input input {
  border: 1px solid #dddddd;
  font-size: 15px;
  padding: 5px 45px 6px 10px;
  position: relative;
  width: 275px;
  border-radius: 35px;
}

.error-form-input .error-s-button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 7px 15px;
  position: absolute;
  right: 0;
  top: 0;
}

.search-error-wrapper .home-bacck-button {
  background: #ff5151;
  border-radius: 35px;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  text-transform: uppercase;
  -webkit-transition: 0.4;
  transition: 0.4;
}

/*--
    16. Cart Page Css
-----------------------------*/
.table-content table {
  background: #ffffff none repeat scroll 0 0;
  border-color: #ebebeb;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  text-align: center;
}

.table-content table td {
  border-top: medium none;
  font-size: 16px;
  padding: 20px 10px;
  vertical-align: middle;
  min-width: 140px;
}

.table-content th,
.table-content td {
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

.plantmore-product-quantity > input {
  text-align: center;
  width: 48px;
}

.cart-table .coupon-all {
  /*
        display: flex;
        flex-direction: row;
        justify-content: space-between;*/
  margin-top: 50px;
}

.cart-table .coupon-all .coupon h3 {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: -8px;
}

.cart-table .coupon-all .coupon input {
  border: 1px solid #999999;
  font-size: 14px;
  margin-right: 10px;
  padding: 4px 10px;
  width: 130px;
}

.cart-table .coupon-all .coupon input.button {
  background: #000000;
  border: 0 none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  letter-spacing: 1px;
  line-height: 36px;
  padding: 0 14px;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: inherit;
}

.cart-table .coupon-all .coupon input.button:hover {
  background: #ff5151;
}

@media only screen and (max-width: 479px) {
  .cart-table .coupon-all .coupon input {
    width: 125px;
  }
}

.cart-table .coupon-all .coupon2 {
  margin-bottom: 40px;
}

.cart-table .coupon-all .coupon2 input {
  margin-right: 8px;
}

.cart-table .coupon-all .coupon2 input.submit {
  padding: 8px 15px;
  background: #000000;
  color: #ffffff;
  border: 1px solid #000000;
}

.cart-table .coupon-all .coupon2 input.submit:hover {
  background: #ff5151;
  border: 1px solid #ff5151;
}

.cart-table .coupon-all .coupon2 .continue-btn {
  padding: 8px 15px;
  background: #000000;
  color: #fff;
}

.cart-table .coupon-all .coupon2 .continue-btn:hover {
  background: #ff5151;
  color: #fff;
}

.cart-page-total {
  margin-top: 40px;
}

.cart-page-total h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: capitalize;
}

.cart-page-total ul {
  border: 1px solid #dddddd;
}

.cart-page-total ul li {
  border-bottom: 1px solid #ebebeb;
  color: #252525;
  font-family: "Montserrat",sans-serif;
  font-size: 15px;
  font-weight: 700;
  list-style: outside none none;
  padding: 10px 30px;
}

.cart-page-total ul li:last-child {
  border: none;
}

.cart-page-total ul li span {
  float: right;
}

.cart-page-total .proceed-checkout-btn {
  background: #000000;
  color: #ffffff;
  display: inline-block;
  margin-top: 30px;
  padding: 8px 16px;
  text-transform: capitalize;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.cart-page-total .proceed-checkout-btn:hover {
  background: #ff5151;
}

.plantmore-product-add-cart > a {
  background: #000000;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 30px;
  text-transform: capitalize;
  -webkit-transform: 0.4s;
  -ms-transform: 0.4s;
  transform: 0.4s;
}

.plantmore-product-add-cart > a:hover {
  background: #ff5151;
}

.in-stock {
  color: #30b878;
}

.out-stock {
  color: #A80135;
}

/*-- 
   17. Checkout Page Css
------------------------------*/
.coupon-accordion h3 {
  background: #f6f6f6;
  border-top: 3px solid #ff5151;
  color: #515151;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 30px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  text-transform: capitalize;
}

.coupon-accordion h3 .coupon {
  color: #ff5151;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.coupon-accordion h3 .coupon:hover {
  color: #000000;
}

.coupon-content {
  border: 1px solid #dddddd;
  margin-bottom: 30px;
  padding: 20px;
  display: none;
}

.coupon-info p.form-row-first {
  float: left;
  width: 48%;
}

@media only screen and (max-width: 479px) {
  .coupon-info p.form-row-first {
    width: 100%;
  }
}

.coupon-info p.form-row-last {
  float: right;
  width: 48%;
}

@media only screen and (max-width: 479px) {
  .coupon-info p.form-row-last {
    width: 100%;
  }
}

.coupon-info .remember {
  margin-left: 10px;
}

.coupon-info .remember span {
  margin-left: 5px;
}

.coupon-input label {
  display: block;
  font-size: 14px;
}

.coupon-input input {
  border: 1px solid #999999;
  color: #000000;
  padding: 5px 10px;
  width: 100%;
  font-size: 14px;
}

.coupon-input span.required {
  color: red;
}

.checkout-coupon input {
  border: 1px solid #999999;
  color: #555;
  padding: 5px 10px;
  width: auto;
}

.checkout-coupon input:focus {
  outline: none;
}

.checkout-coupon .button-apply-coupon {
  margin: -5px 0 0 10px;
  padding: 7.2px 11px;
}

@media only screen and (max-width: 479px) {
  .checkout-coupon .button-apply-coupon {
    margin: 10px 0 0 0px;
  }
}

.shoping-checkboxt-title {
  border-bottom: 1px solid #dddddd;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.single-form-row {
  margin-bottom: 20px !important;
}

.single-form-row label {
  font-size: 14px;
  margin-bottom: 2px;
}

.single-form-row label span.required {
  color: red;
}

.single-form-row input {
  border: 1px solid #999999;
  color: #666;
  font-size: 14px;
  padding: 5px 12px;
  width: 100%;
}

.single-form-row input::focus {
  outline: none;
}

.single-form-row textarea {
  border: 1px solid #999999;
  color: #555555;
  padding: 12px;
  width: 100%;
  font-size: 14px;
}

.single-form-row.m-0 {
  margin: 0 !important;
}

.checkout-box-wrap p {
  font-size: 14px;
}

.checkout-box-wrap .ship-box-info {
  display: none;
}

.account-create {
  display: none;
}

.account-create .creat-pass > span {
  color: red;
}

.nice-select select {
  height: 35px;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  color: #555;
  border: 1px solid #999;
}

@media only screen and (max-width: 767px) {
  .your-order-wrapper {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .your-order-wrapper {
    margin-top: 60px;
  }
}

.your-order-wrap {
  background: #f6f6f6;
}

.your-order-table {
  padding: 20px 30px;
}

.your-order-table table {
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #d8d8d8;
  border-right: medium none;
  font-size: 14px;
  padding: 15px 0;
  text-align: center;
}

.your-order-table table th {
  border-top: medium none;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.your-order-table table .shipping > th {
  vertical-align: top;
}

.payment-method {
  padding: 20px 30px;
}

.payment-accordion h3 a {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  padding-left: 31px;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
}

.payment-accordion h3 a::before, .payment-accordion h3 a::after {
  content: "\f216";
  display: inline-block;
  font-family: ionicons;
  font-size: 19px;
  left: 0;
  position: absolute;
  top: 0px;
}

.payment-accordion h3 a img {
  height: 60px;
  display: block;
}

.payment-accordion h3.open a::after {
  content: "\f207";
}

.payment-accordion p {
  font-size: 14px;
  padding-left: 20px;
}

.order-button-payment {
  margin-top: 30px;
}

.order-button-payment input {
  background: #ff5151;
  border: medium none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  text-transform: uppercase;
  width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.order-button-payment input:hover {
  background: #000000;
  color: #ffffff;
}

/*--
    18. About Css
-------------------*/
.section-inner-ptb {
  padding: 180px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-inner-ptb {
    padding: 120px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-inner-ptb {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-inner-ptb {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 479px) {
  .section-inner-ptb {
    padding: 60px 0;
  }
}

.welcome-about-us {
  background: #0d202c;
  position: relative;
  overflow: hidden;
}

.welcome-about-us .start-now {
  margin-top: 25px;
}

.welcome-about-us .start-now .start-btn {
  padding: 12px 45px;
  background: #ff5151;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
}

.welcome-about-us .start-now .start-btn:hover {
  color: #ffffff;
}

.welcome-video {
  float: left;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 45%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .welcome-video {
    position: inherit;
    width: 100%;
    background-size: cover;
  }
}

/*-- About Us Css */
.about-us-contents {
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .about-us-contents {
    padding-right: 0;
  }
}

.about-us-contents h3 {
  font-weight: 500;
  margin-bottom: 15px;
}

.about-us-contents h3 > span {
  color: #ff5151;
}

.about-us-contents .about-us-btn > a {
  margin-top: 30px;
  letter-spacing: 1px;
  padding: 10px 45px;
  background: #ff5151;
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  text-transform: uppercase;
}

.about-us-contents .about-us-btn > a:hover {
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .about-us-contents .about-us-btn > a {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-image {
    text-align: left !important;
  }
}

.about-us-image a {
  position: relative;
}

.about-us-image a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.about-us-image a span {
  position: absolute;
  top: 50%;
  z-index: 9;
  right: 50%;
  transform: translateY(-50%) translateX(50%);
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-image {
    margin-top: 30px;
  }
}

/*--
    19. Team Area Css
--------------------------*/
.single-team {
  position: relative;
}

.single-team-info {
  padding-top: 25px;
  text-align: center;
  background: #fff;
  position: absolute;
  width: 100%;
  margin-top: 0px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-team-info h5 {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}

.single-team-info p {
  color: #ff5151;
  text-transform: uppercase;
}

.single-team .personsl-socail {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-align: center;
  margin-top: 32px;
}

.single-team .personsl-socail li {
  display: inline-block;
  margin: 0 3px;
}

.single-team .personsl-socail li a {
  display: block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  font-size: 16px;
  background: #ff5151;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
}

.single-team .personsl-socail li a:hover {
  background: #333;
}

.single-team:hover .single-team-info {
  margin-top: -60px;
}

.single-team:hover .personsl-socail {
  visibility: visible;
  opacity: 1;
}

/*--
  20. Project Conunt Css  
-----------------------*/
.project-count-bg {
  background: url(../images/bg/counter-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.project-count-inner_one .counter {
  margin-top: 30px;
  padding-bottom: 5px;
}

.project-count-inner_one .counter h3 {
  color: #ff5151;
  font-size: 60px;
  font-weight: 800;
}

@media only screen and (max-width: 767px) {
  .project-count-inner_one .counter h3 {
    font-size: 50px;
    line-height: 40px;
  }
}

.project-count-inner_one .counter p {
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 15px;
  font-weight: 500;
}

.project-count-inner_one .counter::after {
  position: absolute;
  background: #ff5151;
  content: '';
  width: 50px;
  height: 2px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.project-count-inner_two .single-fun-factor {
  margin-top: 30px;
  text-align: center;
  padding: 30px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
}

.project-count-inner_two .single-fun-factor:hover {
  border-color: #ff5151;
}

.project-count-inner_two .single-fun-factor .counter {
  padding-bottom: 5px;
}

.project-count-inner_two .single-fun-factor .counter h3 {
  color: #ff5151;
  font-weight: 800;
}

@media only screen and (max-width: 767px) {
  .project-count-inner_two .single-fun-factor .counter h3 {
    font-size: 50px;
    line-height: 40px;
  }
}

.project-count-inner_two .single-fun-factor .counter p {
  color: #ffffff;
  font-size: 20px;
  line-height: 14px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 0;
  font-weight: 500;
}

/*--
    21. Contact css
-----------------------*/
.contact-form-info {
  padding: 20px 50px 20px 0;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-info {
    padding: 0;
  }
}

.contact-title > h3 {
  font-size: 22px;
  margin-bottom: 30px;
  font-weight: 600;
}

.contact-input {
  display: block;
  margin: 0 -10px;
  overflow: hidden;
}

.contact-inner {
  float: left;
  margin: 0 0 20px;
  padding: 0 10px;
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .contact-inner {
    width: 100%;
  }
}

.contact-inner input, .contact-inner textarea {
  border: 1px solid #dddddd;
  color: #222222;
  font-size: 14px;
  height: 45px;
  padding-left: 15px;
  width: 100%;
}

.contact-inner.contact-message {
  width: 100%;
}

.contact-inner.contact-message textarea {
  height: 150px;
  padding: 15px;
}

.contact-infor {
  background: #f2f2f2 none repeat scroll 0 0;
  padding: 20px 30px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-infor {
    margin-top: 60px;
  }
}

.contact-dec > p {
  margin-bottom: 30px;
}

.contact-address {
  margin-bottom: 10px;
}

.contact-address li {
  border-top: 1px solid #e4e4e4;
  font-size: 14px;
  padding: 10px 0;
}

.contact-address li:first-child {
  border-top: none;
}

.contact-address li i {
  margin-right: 5px;
}

.work-hours {
  margin-bottom: 15px;
}

.contact-submit-btn .submit-btn {
  background: #333333 none repeat scroll 0 0;
  border: 0 none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.contact-submit-btn .submit-btn:hover {
  background: #ff5151;
}

.form-messege {
  margin-bottom: 0;
  margin-top: 3px;
  font-size: 14px;
}

.form-messege.error {
  color: red;
  font-style: italic;
}

.form-messege.success {
  color: #008000;
  font-style: italic;
}
